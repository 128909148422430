import axios from 'axios';

/*
  Please note the headers I added hier because of the CORS issue that occured if headers are not being passed throught 
  You might need to move the keys the the .env file pulls them from there for safety
*/

const headers = {
  Authorization: `x-api-key: ${process.env.REACT_APP_AGENT_HIERARCHY_KEY}`,
  "Content-Type": "application/json",
};

const authHeaders = () => {
  axios.defaults.headers.get["x-api-key"] =
  process.env.REACT_APP_AGENT_HIERARCHY_KEY;

  axios.interceptors.request.use((config) => {
    config.headers["x-api-key"] = process.env.REACT_APP_AGENT_HIERARCHY_KEY;
    return config;
  });
};

authHeaders();

class TeamMembersProvider {

    static async getTeamMembers() {
        let results = []
        await axios.get(process.env.REACT_APP_AWS_API+'/TeamMembers?UserPrincipalName=' + localStorage.getItem('userPrincipalName'))
            .then(res => {
                if (res.data.length > 0) {
                    results = res.data;
                }
            })
            .catch(err => {
                console.log(err);
            })
        return results;
    }

    static async createUpdateCampaignsAndManagerRequest(data) {
      const payloadObject = {
        auth: {
          UserPrincipalName: localStorage.getItem("userPrincipalName"),
          AccessToken: localStorage.getItem("MSAuthToken")
        },
        data: data
      }
        try {
          const postdata = await axios.post(
            process.env.REACT_APP_AWS_API + '/CreateUpdateCampaignsAndManagerRequest', payloadObject,
            {headers: {
              'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
            }});
            console.log('postdata:', postdata)
            return postdata.data;
        }
         catch (error) 
        {
          console.log(error)
        }
    }

    static async createBulkEmployeeRemovalRequest(data) {
      const payloadObject = {
        auth: {
          UserPrincipalName: localStorage.getItem("userPrincipalName"),
          AccessToken: localStorage.getItem("MSAuthToken")
        },
        data: data
      }
        try {
          const postdata = await axios.post(
            process.env.REACT_APP_AWS_API + '/CreateEmployeesOffboardingRequest', payloadObject,
            {headers: {
              'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
            }});
            return postdata.data;
        }
         catch (error) 
        {
          console.log(error)
        }
    }

    static async approveEmployeeRemovalRequest(data) {
      const payloadObject = {
        auth: {
          UserPrincipalName: localStorage.getItem("userPrincipalName"),
          AccessToken: localStorage.getItem("MSAuthToken")
        },
        data: data
      }
        try {
          const postdata = await axios.post(
            process.env.REACT_APP_AWS_API + '/ApproveEmployeesOffboardingRequest', payloadObject,
            {headers: {
              'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
            }});
            return postdata.data;
        }
         catch (error) 
        {
          console.log(error)
        }
    }

    static async createEscalationRequest({type, data}) {
      const payloadObject = {
        auth: {
          UserPrincipalName: localStorage.getItem("userPrincipalName"),
          AccessToken: localStorage.getItem("MSAuthToken")
        },
        data: data
      };

      try {
        const postdata = await axios.post(
          process.env.REACT_APP_AWS_API + '/CreateEscalationRequest', payloadObject,
          {headers: { 'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY }});
          return postdata.data;
      }
       catch (error) 
      {
        console.log(error)
      }
    }
}

export default TeamMembersProvider;