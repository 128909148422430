import React from 'react'
import { useEffect, useRef, useState } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import MovementReasonProvider from "../../../providers/MovementReasonProvider";



const MovementReasonsSelect = ({requestType, setReasonId, register , errors, errorMessages, setValue}) => {
  const [movementReasons, setMovementReasons] = useState([]);
 
  const getMovementReasons = async() => {
    const reasons = await MovementReasonProvider.getMovementReasons(requestType);
    setMovementReasons(reasons);
  }

  useEffect(async() => {
    await getMovementReasons();

    if(register && typeof register === 'function'){
      register('movementReasons', errorMessages['movementReasons']);
    }
  }, [])

  return (
    <SuiBox mt={4} mb={2} lineHeight={1}>
      <SuiTypography variant="h6">Movement Reason</SuiTypography>
      <SuiBox position="relative">
        <Select
          key={`movementReasons`}
          id={`movementReasons`}
          name={`movementReasons`}
          // register={register('movementReasons', errorMessages['movementReasons'])}
          options={movementReasons.map((reasons) => ({
                value: reasons.Id,
                label: `${reasons.Value}`
          }))}
          placeholder={`Select a movement reason`}
          onChange={(e) => {setReasonId(e.value); setValue('movementReasons', e.value)}}
          classNamePrefix="custom-select"
          className="select-control select-one select-with-html"
        />
      </SuiBox>

      {errors && errors['movementReasons'] && <p className="error msg pad">{errorMessages['movementReasons']['required']}</p>}
    </SuiBox>
  )
}

export default MovementReasonsSelect