import axios from 'axios';

class RoleIdProvider {

    static async getRole(userPrincipalName) {
        let results = []
        await axios.get(process.env.REACT_APP_AWS_API+'/Role?UserPrincipalName=' + userPrincipalName)
            .then(res => {
                if (res.data.length > 0) {
                    results = res.data;
                }
            })
            .catch(err => {
                console.log(err);
            })
        return results;
    }
}

export default RoleIdProvider;