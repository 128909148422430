import { useEffect, useState, useCallback  } from "react";
import React from 'react';
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import SuiInput from "components/SuiInput";
import MovementReasonsSelect from "../components/MovementReasonsSelect";
import CampaignProvider from "../../../providers/CampaignProvider";
import UsersProvider from "../../../providers/UsersProvider";
import { useForm } from 'react-hook-form';
import ActionRequestButtons from "../components/ActionRequestButtons";
import requesterErrorMessages from '../../../static/messages/errorMessages.json';
import ErrorMessage from "../components/ErrorMessage";


export const UsersMovementEscalation = React.memo(({ setApiRequestObj }) => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue  } = useForm();
  const [roleNameOptions] = useState([
    {key: 1, value: 'Agent' , manager :'Junior Manager'},
    // {key: 2, value: 'Junior Manager'},
    // {key: 3, value: 'General manager'}
  ]);
  const [errorMessages, setErrorMessages] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [suboridinateOptions, setSuboridinateOptions] = useState([]);
  const [currentManagerOptions, setManagerOptions] = useState(null);
  
  const [userRoleName, setUserRoleName] = useState('');
  const [approvers, setApprovers] = useState(null);
  const [selectedCurrentManager, setSelectedCurrentManager] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [selectedNewManager, setSelectedNewManager] = useState(null);
  const [movementReasonId, setMovementReasonId] = useState(null);
  const [loggedInUserRole, setLoggedInUserRole] = useState(localStorage.getItem("roleName"));

  

  const onSubmit = () => {  
  
    if (selectedNewManager && selectedCurrentManager && selectedNewManager.EmployeeNumber === selectedCurrentManager.EmployeeNumber) {return;}

    const selectedSuborinates = selectedUsers.map((user) => ({
      Name: user.name,
      EmployeeNumber: user.value
    }))

    let approvers = [selectedNewManager?.EmployeeNumber];
    
    if(selectedNewManager?.ManagerUsers[0]?.EmployeeNumber) {
      approvers.push(selectedNewManager?.ManagerUsers[0]?.EmployeeNumber);
    } 
      console.log('approvers:', approvers)

      console.log('selectedNewManager:', selectedNewManager)
      console.log('selectedNewManager?.ManagerUsers[0]:', selectedNewManager?.ManagerUsers[0])
    
      setApiRequestObj({
        RequestType: "UPDATE CAMPAIGNS AND MANAGER",
        RequestDescription: "portal-request",
        CreatedBy: localStorage.getItem("UserName") || null,
        RequesterUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        UserHierarchyMovementReasonId: movementReasonId,
        ApproverUserEmployeeNumbers: approvers,
        EffectiveDate: null,
        ManagerUserEmployeeNumber: selectedNewManager?.EmployeeNumber,
        EmployeeMovements: selectedSuborinates
      })     
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  useEffect(async() => {
    setErrorMessages(requesterErrorMessages['teamManagement'].requests['usersMovementRequest']);

    const fetchManager = async() => {
      getCurrentManagerInfo(localStorage.getItem("EmployeeNumber"))
    }

    if(!["Admin", "General Manager"].includes(loggedInUserRole)){
      await fetchManager();
    }
  }, [])

  const getUsers = async(roleName) => {
    roleNameOptions.find
    const userManagerRole = roleNameOptions.find(role => role.value === roleName);

    let users = [];
    setFetchingData(true);
    if (userManagerRole.manager === 'Junior Manager') {
      const [juniorManagerUsers, trainerUsers] = await Promise.all([
        UsersProvider.getUsersByRole({ roleName: userManagerRole.manager }),
        UsersProvider.getUsersByRole({ roleName: 'Trainer' })
      ]);
    
      // Combine the arrays
      users = [...juniorManagerUsers, ...trainerUsers];
    }else{
      users = await UsersProvider.getUsersByRole({ roleName: userManagerRole.manager });
    }


    let options = users.map((user) => ({
      value: user.EmployeeNumber	,
      label: `${user.FullName} ( ${user.EmployeeNumber} )`
    }));

    setFetchingData(false);
    setManagerOptions(options);
  };

  const getCurrentManagerInfo = async(employeeNumber) => {
    // setManagerSuboridinates([]);
    console.log('employeeNumber:', employeeNumber)
    setFetchingData(true);
    const managerData = await UsersProvider.getUsersSearch({ employeeNumber: employeeNumber });

    if(managerData && managerData.length === 1){
      setSelectedCurrentManager(managerData[0]);
      console.log('MANAGER:', managerData[0])
      if(managerData[0].SubordinateUsers && managerData[0].SubordinateUsers.length > 0){
        let options = managerData[0].SubordinateUsers.map((user) => ({
          value: user.EmployeeNumber	,
          label: `${user.FullName} ( ${user.EmployeeNumber} )`,
          name: user.FullName
        }));
        setSuboridinateOptions(options);
        // setFetchingData(false);

      }
    }
    setFetchingData(false);
  }
  
  const getNewManagerInfo = async(employeeNumber) => {

    setFetchingData(true);
    const managerData = await UsersProvider.getUsersSearch({ employeeNumber: employeeNumber });

    if(managerData && managerData.length === 1){
      setSelectedNewManager(managerData[0]);
    }

    setFetchingData(false);
  }
  
  return (
    <>
    {errorMessages && <>
          
        {!["Admin", "General Manager"].includes(loggedInUserRole) && <>
          <SuiTypography variant="h6">Current Manager (You)</SuiTypography>
          <SuiInput
            label={`User`}
            id={`userEscalationRef`}
            name={`userEscalationRef`}
            disabled={true}
            value={`${localStorage.getItem("fullName")} ( ${localStorage.getItem("EmployeeNumber")} )`}
          />
        </>}
      <SuiBox mt={4} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">User Movement Role</SuiTypography>
        <SuiBox position="relative">
          <Select
            key={`roleName`}
            id={`roleName`}
            register={register('roleName', errorMessages['roleName'])}
            options={roleNameOptions.map((role) => ({
                  value: role.value,
                  label: `${role.value}`,
                  rep: `${role.manager}`
            }))}
            placeholder={`Select the user's role you want to move`}
            onChange={(e) => {setUserRoleName(e.value);  setValue('roleName', e.value); getUsers(e.value);}}
            isSearchable={false}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['roleName'] && <p className="error msg pad">{errorMessages['roleName']['required']}</p>}
      </SuiBox>
      <SuiBox mt={4} mb={2} lineHeight={1}>
        {["Admin", "General Manager"].includes(loggedInUserRole) && 
        <>
          <SuiTypography variant="h6">Current Manager</SuiTypography>
          <SuiBox mt={1} position="relative">
          <Select
            key={`currentManagerOptions`}
            id={`currentManagerOptions`}
            register={register('currentManagerOptions', errorMessages['currentManagerOptions'])}
            isLoading={fetchingData}
            options={currentManagerOptions || []}
            placeholder={`Select the manager you want to move users from`}
            onChange={(e) => { getCurrentManagerInfo(e.value); setValue('currentManagerOptions', e.value);}}
            isSearchable={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
          </SuiBox>
          {errors['currentManagerOptions'] && <p className="error msg pad">{errorMessages['currentManagerOptions']['required']}</p>}
        </>}
      </SuiBox>
      <SuiBox mt={4} mb={6} lineHeight={1}>
        <SuiTypography variant="h6">{userRoleName || 'User'}s to Move</SuiTypography>
        <SuiBox mt={1} position="relative">
          <Select
            key={`userOptions`}
            id={`userOptions`}
            register={register('userOptions', errorMessages['userOptions'])}
            isLoading={fetchingData}
            isDisabled={fetchingData}
            options={suboridinateOptions || []}
            placeholder={`Select the users to want to move from ${selectedCurrentManager?.FullName || 'current manager'}`}
            onChange={(e) => {setSelectedUsers(e); setValue('userOptions', e);}}
            isSearchable={true}
            isMulti
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['userOptions'] && <p className="error msg pad">{errorMessages['userOptions']['required']}</p>}
      </SuiBox>

      <SuiBox mt={6} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">New Manager Selection</SuiTypography>
        <SuiBox mt={1} position="relative">
          <Select
            key={`newManagerOptions`}
            id={`newManagerOptions`}
            register={register('newManagerOptions', errorMessages['newManagerOptions'])}
            isLoading={fetchingData}
            isDisabled={fetchingData}
            options={currentManagerOptions || []}
            placeholder={`Select the manager you want to move users to`}
            onChange={(e) => {getNewManagerInfo(e.value); setValue('newManagerOptions', e.value);}}
            isSearchable={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['newManagerOptions'] && <p className="error msg pad">{errorMessages['newManagerOptions']['required']}</p>}
        {selectedNewManager && 
        selectedCurrentManager && 
        selectedNewManager.EmployeeNumber === selectedCurrentManager.EmployeeNumber && <p className="error msg pad">Current and New Manager can't be the same</p>}      
      </SuiBox>

      <MovementReasonsSelect
        requestType={'movement'} 
        setReasonId={setMovementReasonId} 
        register={register}
        errorMessages={errorMessages}
        errors={errors} 
        setValue={setValue}
       />
      <ActionRequestButtons onSubmit={handleFormSubmit} isDisabled={fetchingData}
      />
    </>}
    </>
  )
});
