import { useState, useEffect } from "react";
import { map, size } from "lodash";
import clsx from "clsx";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Card from "@mui/material/Card";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tree, TreeNode } from "react-organizational-chart";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SuiBox from "components/SuiBox";
import UsersProvider from "../../providers/UsersProvider.js";
import { createTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import SuiTypography from "components/SuiTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 16,
    boxShadow: "0px 7px 10px 0px rgba(0, 0, 0, 0.15)"
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#ECECF4"
  }
}));

const theme = createTheme({
  palette: {
    background: "#ffffff"   
  },
  fontFamily: "Roboto, sans-serif"
});

const Organization = ({ org, onCollapse, collapsed }) => {
  let backgroundColor = "white";
  const classes = useStyles();
  let teamSize2 = [];

  if(org && org?.SubordinateUserIds){
    teamSize2 = org?.SubordinateUserIds.split(',')
  }
 
  return (
      <Card
         variant="outlined"
         className={classes.root}
         style={{ backgroundColor }}
       >
         <CardHeader
           avatar={
             <Tooltip
               arrow
             >
               <Badge
                 style={{ cursor: "pointer" }}
                 color={teamSize2 && teamSize2.length > 0 ? `primary` : 'secondary'}
                 showZero
                 invisible={!collapsed}
                 overlap="circle"
                 badgeContent={teamSize2.length}
                 onClick={onCollapse}
               >
                 {/* <Avatar className={classes.avatar}>
                   <PersonIcon color="primary" />
                 </Avatar> */}
                 <div style={{width:'40px', height:'40px', background:'#ececf4', borderRadius:'50%', marginTop: '10px'}}>
                  <SuiTypography fontWeight="thin" variant="overline">
                    {org.Role === 'Head of Sales' ? 'HOS' : 
                     org.Role === 'General Manager' ? 'GM' : 
                     org.Role === 'Junior Manager' ? 'JM' : 
                     org.Role === 'Agent' ? 'A' : ''}
                  </SuiTypography>
                 </div>
               </Badge>
             </Tooltip>
           }
           title={<SuiBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <SuiTypography fontWeight="medium" variant="h6" >{org.FullName}</SuiTypography>
            <SuiTypography fontWeight="medium" variant="overline" >({org.EmployeeNumber})</SuiTypography>
           </SuiBox>}
         />
   
         {/* <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
           <MenuItem onClick={handleClose}>
             <ListItemIcon>
               <PersonIcon color="primary" />
             </ListItemIcon>
             <ListItemText primary="Add Sub Profile" />
           </MenuItem>
           <MenuItem onClick={handleClose}>
             <ListItemIcon>
               <AccountBalanceIcon color="secondary" />
             </ListItemIcon>
             <ListItemText primary="Add Sub Account" />
           </MenuItem>
         </Menu> */}
         <IconButton
           size="small"
           onClick={onCollapse}
           className={clsx(classes.expand, {
             [classes.expandOpen]: !collapsed
           })}
         >
           <ExpandMoreIcon />
         </IconButton>
       </Card> 
  );
}

const Agent = ({ a }) => {
  const classes = useStyles();
  const opacity = 1;
  return (
    <Card variant="outlined" className={classes.root} style={{ cursor: "pointer", opacity }}>
      {/* <SuiBox display="flex" alignItems="center" shadow="md" px={2} py={2} gap={2}>
        <SuiTypography fontWeight="medium" variant="overline" >{a?.FullName}</SuiTypography>
      </SuiBox> */}

      <SuiBox display="flex" gap="0"  shadow="md"  px={2} py={2} justifyContent="center" alignItems="center" flexDirection="column">
        <SuiTypography fontWeight="medium" variant="overline" >{a?.FullName}</SuiTypography>
        <SuiTypography fontWeight="medium" variant="overline" >({a?.EmployeeNumber})</SuiTypography>
      </SuiBox>
    </Card>
  );
}

const TreeStructure = (props) => {
  return (
    <Tree {...props} lineWidth={"2px"} lineColor={"#bbc"} lineBorderRadius={"12px"}>
      {props.children}
    </Tree>
  )
}

const Node = ({ o, parent }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [collapsed, setCollapsed] = useState(parent?.SubordinateUsers || o?.collapsed);
  const T = parent ? TreeNode : TreeStructure;
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // useEffect(async() => {
  //   if(!collapsed && parent){
  //     setIsSearching(true);
  //     console.log('parent:', parent)
  //     const userHiearchy = await getUserHierarchy(parent.UserPrincipalName);
  //     console.log('userHiearchy:', userHiearchy)
  //     setIsSearching(false);
  //   }
  // }, [collapsed]);
  
  return collapsed ? (
    <T key={o.id} label={ <Organization org={o} onCollapse={handleCollapse} collapsed={collapsed} />} />
  ) : (
    <T key={o.id} label={ <Organization org={o} onCollapse={handleCollapse} collapsed={collapsed}/> }>
      {map(o.SubordinateUsers, (c) => (
        c.Role !="Agent"  ? <Node o={c} parent={o} /> : <TreeNode label={<Agent a={c} />}></TreeNode>
      ))}
    </T>
  );
}

// const getUserHierarchy = async(userPrincipalName) => {
//     try {
//       const res = await UsersProvider.getUsersSearch({userPrincipalName});
//       return res ? res : null;

//     } catch (error) {
//       console.log('error:', error)
//     }
// }

export default function HierarchyDiagram(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box bgcolor="background" padding={4} height="80vh">
        <Node o={props.myTeam} />
      </Box>
    </ThemeProvider>
  );
}
