import Button from "@mui/material/Button";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Divider from "@mui/material/Divider";
import { useFormContext } from 'react-hook-form';
import { setOpenRequestor, useSoftUIController } from "context";

const ActionRequestButtons = ({onSubmit, isDisabled}) => {
// const { handleSubmit } = useFormContext();
const handleCloseRequestor = () => setOpenRequestor(dispatch, false);
const [controller, dispatch] = useSoftUIController();


  return (
  <>
    <Divider sx={{marginTop: '3rem'}} />
    <SuiBox mb={5} mt={4} textAlign="center">
        <SuiBox display="flex" justifyContent="center">
        <SuiBox mr={1.5}>
            <SuiButton disabled={isDisabled} onClick={onSubmit} type="button" component={Button} color="dark" variant="gradient">
                Submit Request
            </SuiButton>
        </SuiBox>
        <SuiButton component={Button} color="dark" variant="outlined" onClick={handleCloseRequestor}>
            Cancel
        </SuiButton>
        </SuiBox>
    </SuiBox>
    </>
  )
}

export default ActionRequestButtons