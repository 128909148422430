import axios from 'axios'

class ApprovalProvider {
  
  static async getApproverApprovals(requestStatus) {
    let results = [];

    const res = await axios.get(process.env.REACT_APP_AWS_API + '/GetApprovalsForApprover?Limit=25&RequestStatus=' + requestStatus, 
      {headers: {
        'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY,
        'userprincipalname': localStorage.getItem("userPrincipalName"),
        'accesstoken': localStorage.getItem("MSAuthToken")
      }})
      .then(res=>{
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err=>{
        console.log(err)
      })  
      return results;
  }

  static async getAdminApprovals(requestStatus) {
    let results = []
    const res = await axios.get(process.env.REACT_APP_AWS_API + '/GetAdminApprovals?RequestStatus=' + requestStatus, 
      {headers: {
        'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY,
        'userprincipalname': localStorage.getItem("userPrincipalName"),
        'accesstoken': localStorage.getItem("MSAuthToken")
      }})
      .then(res=>{
      if (res.data.length > 0) {
          results = res.data
        }
    })
    .catch(err=>{
      console.log(err)
    })  
    return results;
  }

  static async getRequestorApprovals(requestStatus) {
    let results = [];
    
    let url = process.env.REACT_APP_AWS_API + '/GetApprovalsForRequester?Limit=25';
    if(requestStatus){
      url += `&RequestStatus=${requestStatus}`
    }
    const res = await axios.get(url , 
      {headers: {
        'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY,
        'userprincipalname': localStorage.getItem("userPrincipalName"),
        'accesstoken': localStorage.getItem("MSAuthToken")
      }})
    .then(res=>{
      if (res.data.length > 0) {
        results = res.data
      }
    })
    .catch(err=>{
      console.log(err)
    })  
    return results;
  }
  
  static async getRequestInformation(RequestId) {
      try {
        const res = await axios.get(process.env.REACT_APP_AWS_API + '/GetUserRequest?Id=' + RequestId, 
        {headers: {
          'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY,
          'userprincipalname': localStorage.getItem("userPrincipalName"),
          'accesstoken': localStorage.getItem("MSAuthToken")
        }})
        if (res.data) {
          return res.data;
        }
        return []
      }
       catch (error) 
      {
        console.log(error)
      }
  }

  static async approveEscalation({data}) {
    const payloadObject = {
      auth: {
        UserPrincipalName: localStorage.getItem("userPrincipalName"),
        AccessToken: localStorage.getItem("MSAuthToken")
      },
      data: data
    }
      try {
        const postdata = await axios.post(process.env.REACT_APP_AWS_API + '/ApproveEscalationRequest ', payloadObject, 
        {headers: {
          'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
        }})
        if (postdata.data) {
          return postdata.data;
        }
        return []
      }
       catch (error) 
      {
        console.log(error)
      }
  }

  static async auditSubmission(payload) {
      try {
        const postdata = await axios.post(process.env.REACT_APP_AWS_API + '/CreateAuditAgentHierarchy ', payload, 
        {headers: {
          'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
        }})
        if (postdata.data) {
          return postdata.data;
        }
        return null;
      }
       catch (error) 
      {
        console.log(error)
      }
  }

  static async approveMovementRequest(data) {
    const payloadObject = {
      auth: {
        UserPrincipalName: localStorage.getItem("userPrincipalName"),
        AccessToken: localStorage.getItem("MSAuthToken")
      },
      data: data
    }
      try {
        const postdata = await axios.post(
          process.env.REACT_APP_AWS_API + '/ApproveUpdateCampaignsAndManagerRequest', payloadObject,
          {headers: {
            'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
          }});
          return postdata.data;
      }
       catch (error) 
      {
        console.log(error)
      }
  }

  static async createAndApproveUserCreation(data) {
    const payloadObject = {
      auth: {
        UserPrincipalName: localStorage.getItem("userPrincipalName"),
        AccessToken: localStorage.getItem("MSAuthToken")
      },
      data: data
    }
      try {
        const postdata = await axios.post(
          process.env.REACT_APP_AWS_API + '/CreateAndApproveNewEmployeesOnboardingRequest', payloadObject,
          {headers: {
            'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
          }});
          return postdata.data;
      }
       catch (error) 
      {
        console.log(error)
      }
  }

  static async createAndApproveUpdateCampaignsRequest(data) {
    const payloadObject = {
      auth: {
        UserPrincipalName: localStorage.getItem("userPrincipalName"),
        AccessToken: localStorage.getItem("MSAuthToken")
      },
      data: data
    }
      try {
        const postdata = await axios.post(
          process.env.REACT_APP_AWS_API + '/CreateAndApproveUpdateCampaignsRequest', payloadObject,
          {headers: {
            'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
          }});
          return postdata.data;
      }
       catch (error) 
      {
        console.log(error)
      }
  }

  static async updateApproval(id, requestStatus, comment) {

    if(requestStatus && (!requestStatus === 'REJECTED' || !requestStatus === 'APPROVED')) { return {status: "Invalid Status"}}
    
    let results = []
     await axios.post(process.env.REACT_APP_AWS_API+'/UpdateApproval?id=' + id + '&requestStatus=' + requestStatus + '&comment=' + comment)
      .then(res=>{

        if (res && res?.data?.status === 'success') {
            results = res.data
          }
      })
      .catch(err=>{
        console.log(err)
    })  
    return results;
  }

  static async isValidApprovers(approversNumbers) {
    let result = null;
    await axios.post(process.env.REACT_APP_AWS_API + '/IsValidApprovers', {EmployeeNumbers: approversNumbers}, 
    {headers: {
      'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
    }})
      .then(res => {
        if (res && res.data) {
          result = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      })
      return result;
  }
}

export default ApprovalProvider;