import axios from 'axios'

class CampaignProvider {

  static async getUserCampaigns(userPrincipalName) {
    let results = []
    await axios.get(process.env.REACT_APP_AWS_API + '/Campaign?UserPrincipalName=' + userPrincipalName)
      .then(res => {
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  static async getUserCampaignsByReference({Id}) {
    let results = []
    await axios.get(process.env.REACT_APP_AWS_API + '/GetUserCampaigns?Id=' + Id)
      .then(res => {
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  //Needs backend reworkto support new campaign structure
  // static async getCampaigns() {
  //   let results = []
  //   await axios.get(process.env.REACT_APP_AWS_API + '/Campaigns')
  //     .then(res => {
  //       // debugger;
  //       if (res.data.length > 0) {
  //         results = res.data
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  //   return results
  // }

  static async getAgentUserCampaigns(employeeNumber) {
    let results = []
    await axios.get(process.env.REACT_APP_AWS_API + '/GetAgentCampaigns?EmployeeNumber=' + employeeNumber)
      .then(res => {
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  static async getManagerUserCampaigns(employeeNumber) {
    let results = []
    await axios.get(process.env.REACT_APP_AWS_API + '/GetManagerCampaigns?EmployeeNumber=' + employeeNumber)
      .then(res => {
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }
}

export default CampaignProvider;
