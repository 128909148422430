import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import ManagerProvider from "../../../../providers/ManagerProvider";
import TeamMembersProvider from "../../../../providers/TeamMembersProvider";
import UsersProvider from "../../../../providers/UsersProvider";
import ApprovalProvider from "providers/ApprovalProvider";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Select from "react-select";
import SuiInput from "components/SuiInput";
import { useFormContext, useForm } from 'react-hook-form';
import Grid from "@mui/material/Grid";
import './style.scss'


function ManagerCard({rolePageException, manager, disableEscalations, alert, getApprovals, setDisplayHelpText}) {
  // Declare States and Variables
  const [buttonPopup, setButtonPopup] = useState(false);
  const [escalateProcess, setEscalateProcess] = useState(false);
  const [disableSubmission, setDisableSubmission] = useState(true);
  // const [manager, setManager] = useState([]);
  const [escalationManager, setEscalationManager] = useState({});
  const [managerDisabled, setManagerDisabled] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const searchTextRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSearchResults, setFilteredSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { handleSubmit, register } = useForm();

  const getSearchResults = async() => {
    if(searchResults.length > 0) {
      setFilteredSearchResults(searchResults);
      return;
    }
    let res = await UsersProvider.getUsersByRole({roleName: 'Junior Manager'});
    setSearchResults(res);
    setFilteredSearchResults(res);
  }

  const submitEscalation = async () => {
    setShowSuggestions(false);
    setLoading(true);
    // let userObj = await UsersProvider.getUsersSearch({userPrincipalName: localStorage.getItem("userPrincipalName")});
    let userObj = await UsersProvider.getUserHierarchyByEmployeeNumber({employeeNumber: localStorage.getItem('EmployeeNumber')});
    let userObj2 = await UsersProvider.getUserHierarchyByEmployeeNumber({employeeNumber: escalationManager.EmployeeNumber});

    if (userObj && userObj.Manager.EmployeeNumber === escalationManager.EmployeeNumber){ 
      setErrorMessage(`Already assigned to that manager ( ${userObj.Manager.FullName} )`)
      setDisableSubmission(true);
      setSearchText('');
    }
    else{
      let approvers = [];
      if(userObj2?.EmployeeNumber){
        approvers.push(userObj2.EmployeeNumber)
      }
      if(userObj2?.Manager?.EmployeeNumber){
        approvers.push(userObj2.Manager.EmployeeNumber)
      }
      if(approvers.length == 0){
        approvers.push(escalationManager.EmployeeNumber)
      }

      const userEmployeeNumber = localStorage.getItem("EmployeeNumber");
      const requestPayload = {
          RequestType: "MANAGER ESCALATION",
          RequestDescription: "portal-request",
          CreatedBy: localStorage.getItem("UserName") || null,
          RequesterUserEmployeeNumber: userEmployeeNumber ,
          ApproverUserEmployeeNumbers: approvers,
          ManagerUserEmployeeNumber: escalationManager.EmployeeNumber
      };
      
      let res = await TeamMembersProvider.createEscalationRequest({ data: requestPayload });
      if(res && res.status === 'success'){
        await getApprovals();
        setDisplayHelpText(false);
        alert({
          icon:'success',
          title: 'Escalation Successfully Submitted.',
        });
        setEscalateProcess(false);
      }else{
        alert({
          icon:'error',
          title: 'Escalation Submission Failed.',
        });
      }
    }   
    setLoading(false);
  }

  const filterSearchResults = (value='') => {
    let filteredList = searchResults;
    const filtered = filteredList.filter(user => 
      user.FullName.toLowerCase().includes(value.toLowerCase())
    );

    if(filtered.length === 0 && value.trim() === ''){
      setFilteredSearchResults(searchResults);
      return;
    }

    setFilteredSearchResults(filtered);
  }

  const selectSuggestion = (user) => {
    setErrorMessage('');
    setEscalationManager(user); 
    setSearchText(user.FullName); 
    setShowSuggestions(false); 
    setDisableSubmission(false);
  }

  // Return
  return (
    <Card sx={{ height: "100%", overflow: "visible" }}>
      <SuiBox display="flex" alignItems="center" p={2}>
        <SuiBox sx={{ width: "100%" }}>
          <SuiTypography mb={0.5} variant="h6" fontWeight="medium">
            Manager
          </SuiTypography>
          <SuiBox display="flex" gap={2} alignItems="center">
            <SuiAvatar src={""} alt="something here" variant="rounded" shadow="md"/>
            {escalateProcess ? (
              <SuiBox sx={{width: "70%"}}>
                  <SuiInput
                    placeholder={`Find Manager by full name`}
                    {...register("search-user", { pattern: /^[a-zA-Z0-9]+$/ })}
                    title="Please enter numbers or letters"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      filterSearchResults(e.target.value);
                    }}
                    onFocus={() => {
                      setShowSuggestions(true);
                      getSearchResults();
                    }}
                    inputRef={searchTextRef}
                    value={searchText}
                    fullWidth={true}
                    error={errorMessage}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        getSearchResults();
                      }
                    }}
                  />
                {showSuggestions && (
                  <div className="search-results">
                    <div className="headings">
                      <p>Name</p>
                      <p>Employee number</p>
                    </div>
                    {filteredSearchResults.length == 0 && (
                      <SuiTypography color="secondary" variant="caption">
                        Loading...
                      </SuiTypography>
                    )}
                    {filteredSearchResults.length > 0 &&
                      filteredSearchResults.map((user) => (
                        <div
                          key={user.EmployeeNumber}
                          className="table-row"
                          onClick={() => {
                            selectSuggestion(user);
                          }}
                        >
                          <p>{user.FullName}</p>
                          <p>{user.EmployeeNumber}</p>
                        </div>
                      ))}
                  </div>
                )}
              </SuiBox>
            ) : (
              <SuiTypography color="secondary" variant="h6">
                {manager.FullName}
              </SuiTypography>
            )}
          </SuiBox>
          {errorMessage && <p className="error msg pad">{errorMessage}</p>}

        </SuiBox>

        {rolePageException && <SuiBox ml="auto">
          {!escalateProcess ? (
            <SuiButton
              disabled={disableEscalations}
              onClick={() => {
                setEscalateProcess(true);
              }}
              sx={{ padding: ".5rem 3rem" }}
              color="info"
              variant="gradient"
            >
              Escalate
            </SuiButton>
          ) : (
            <Grid justifyContent={"end"} display={"flex"} gap={2} container>
              <SuiTypography color="secondary" variant="caption">
                Are you sure you want to escalate this request?
              </SuiTypography>
              <SuiBox display={"flex"} gap={2}>
                <SuiButton
                  onClick={() => {
                    submitEscalation();
                  }}
                  disabled={loading || disableSubmission || searchText === "" || disableEscalations}
                  sx={{ padding: ".5rem 3rem" }}
                  color={"success"}
                  variant="gradient"
                >
                  Yes
                </SuiButton>
                <SuiButton
                  onClick={() => {
                    setEscalateProcess(false);
                    setSearchText("");
                  }}
                  sx={{ padding: ".5rem 3rem" }}
                  color="error"
                  variant="gradient"
                >
                  No
                </SuiButton>
              </SuiBox>
            </Grid>
          )}
        </SuiBox>}
      </SuiBox>
    </Card>
  );
}

ManagerCard.propTypes = {
  manager: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ManagerCard;