import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SuiBadge from "components/SuiBadge";
import SuiInput from "components/SuiInput";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { useEffect, useState, useRef } from "react";
import Divider from "@mui/material/Divider";
import BeatLoader from "react-spinners/BeatLoader";
import ApprovalProvider from "../../providers/ApprovalProvider";
import { setOpenRequestor, setRequestor, useSoftUIController } from "context";
import Modal from '../../components/Modals/ModalSkeleton';
import SuiButton from "components/SuiButton";
import { alphaAndNumericWithSpaces } from "../../helpers/regex";




const today = new Date();
const time =
  today.getDate() + ":" + today.getMonth() + ":" + today.getFullYear();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Approvals() {
  const [controller, dispatch] = useSoftUIController();
  const handleRequestorOpen = () => setOpenRequestor(dispatch, !openRequestor);
  const { openRequestor } = controller;

  const [approvalsChanged, setApprovalsChanged] = useState([
    today.getMilliseconds(),
  ]);
  const [declineConfirmation, setDeclineConfirmation] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [requestRef, setRequestRef] = useState(null);
  const comment = useRef('');
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [hover, setHover] = useState("#8392ab");


  const columns = [
    { name: "requester", align: "left" },
    { name: "type", align: "left" },
    { name: "status", align: "left" },
    { name: "createddate", align: "left" },
    { name: "action", align: "center" },
  ];

  const columns2 = [
    { name: "requester", align: "left" },
    { name: "type", align: "left" },
    { name: "status", align: "left" },
    { name: "createddate", align: "left" },
    { name: "action", align: "center" }
  ];

  const ActionRequest = async ({requestId}) => { 
    const requestInfo = await ApprovalProvider.getRequestInformation(requestId);
    setRequestor(dispatch, {'preloadData': {
      ...requestInfo
    }});
    handleRequestorOpen();
    return;

   };

  const Rejected = async () => {
    setConfirmationLoading(true);

    setConfirmationMessage('');
    if (!alphaAndNumericWithSpaces.test(comment.current) || comment.current.length > 120) {
      setConfirmationMessage('Only letters and numbers are allowed in the comment (Max 120 characters)');
      return;
    }

    const requestInfo = await ApprovalProvider.getRequestInformation(requestRef);
    if(requestInfo.RequestStatus !== "NEW"){
      alert({
        icon:'error',
        title: 'This request has already been actioned',
      });
      fetchApprovals();
      return;
    }
    
    const requestStatus = "REJECTED";
    await ApprovalProvider.updateApproval(requestRef, requestStatus, comment.current);
    setDeclineConfirmation(false);
    setRequestRef(null);
    setConfirmationLoading(false);
    fetchApprovals();
  };

  const Cancelled = async () => {
    setConfirmationLoading(true);

    setConfirmationMessage('');
    if (!alphaAndNumericWithSpaces.test(comment.current) || comment.current.length > 120) {
      setConfirmationMessage('Only letters and numbers are allowed in the comment (Max 120 characters)');
      return;
    }

    const requestInfo = await ApprovalProvider.getRequestInformation(requestRef);
    if(requestInfo.RequestStatus !== "NEW"){
      alert({
        icon:'error',
        title: 'This request has already been actioned',
      });
      fetchMyRequests();
      return;
    }
    
    const requestStatus = "CANCELLED";
    await ApprovalProvider.updateApproval(requestRef, requestStatus, comment.current);
    setCancelConfirmation(false);
    setRequestRef(null);
    setConfirmationLoading(false);
    fetchMyRequests();
  };

  useEffect(() => {
    fetchApprovals();
  }, [approvalsChanged]);

  const fetchApprovals = async () => {
    setLoading(true);
    setRows([]);
    let res = [];
    if(localStorage.getItem('roleName') === "Admin"){
       res = await ApprovalProvider.getAdminApprovals('NEW');
    }else{
       res = await ApprovalProvider.getApproverApprovals('NEW');
    }
  
    if (res.length > 0) {
      setRows(
        res.map((item) => ({
          requester: <RowItem text={item.RequesterUserPrincipalName} />,
          type: <RowItem text={item.RequestType} />,
          role: <Function job="Manager" org={item.ApproverUserPrincipalName} />,
          status: (
            <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
              <SuiBox display="flex" flexDirection="column">
                <SuiTypography
                  fontWeight={item.RequestStatus == "NEW" ? "bold" : "normal"}
                  fontSize={item.RequestStatus == "NEW" ? "15px" : "normal"}
                  variant="caption"
                  color="secondary"
                >
                  {item.RequestStatus}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          ),
          comments: (
            <SuiTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              <SuiBox pt={0} px={0}>
                {item.ApproverComment != null ? (
                  <label
                    style={{ color: "#8392ab" }}
                    onChange={(e) => (item.comment = e.target.value)}
                    value={
                      item?.ApproverComment == null
                        ? null
                        : item.ApproverComment
                    }
                  >
                    {item.ApproverComment}
                  </label>
                ) : (
                  <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
                    <SuiInput
                      onChange={(e) => (item.comment = e.target.value)}
                      type="text"
                      name="comments"
                      value={
                        item?.ApproverComment == null
                          ? null
                          : item.ApproverComment
                      }
                      disabled={false}
                    />
                  </SuiBox>
                )}
              </SuiBox>
            </SuiTypography>
          ),
          createddate: <RowItem text={item.CreatedDate} />,
          action: (
            <SuiTypography
              component="a"
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              hidden={item.RequestStatus == "NEW" ? false : true}
            >
              <SuiTypography color="success" onClick={() => ActionRequest({requestId: item.id || item.RequestId})}>
                <span style={{ fontSize: "15px" }}>Approve</span>
              </SuiTypography>
              <Divider style={{ marginTop: "0rem", marginBottom: "0rem" }} />
              <SuiTypography color="error" onClick={() => {setDeclineConfirmation(true); setRequestRef(item.id || item.RequestId)}}>
                <span style={{ fontSize: "15px" }}>Reject</span>
              </SuiTypography>
            </SuiTypography>
          ),
        }))
      );
    } 
    setLoading(false);
  };

  const fetchMyRequests = async () => {
    setLoading(true);
    setRows2([]);
    let res = [];
    res = await ApprovalProvider.getRequestorApprovals('NEW');

  
    if (res.length > 0) {
      setRows2(
        res.map((item) => ({
          requester: <RowItem text={item.RequesterUserPrincipalName} />,
          type: <RowItem text={item.RequestType} />,
          role: <Function job="Manager" org={item.ApproverUserPrincipalName} />,
          status: (
            <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
              <SuiBox display="flex" flexDirection="column">
                <SuiTypography
                  fontWeight={item.RequestStatus == "NEW" ? "bold" : "normal"}
                  fontSize={item.RequestStatus == "NEW" ? "15px" : "normal"}
                  variant="caption"
                  color="secondary"
                >
                  {item.RequestStatus}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          ),
          comments: (
            <SuiTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              <SuiBox pt={0} px={0}>
                {item.ApproverComment != null ? (
                  <label
                    style={{ color: "#8392ab" }}
                    onChange={(e) => (item.comment = e.target.value)}
                    value={
                      item?.ApproverComment == null
                        ? null
                        : item.ApproverComment
                    }
                  >
                    {item.ApproverComment}
                  </label>
                ) : (
                  <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
                    <SuiInput
                      onChange={(e) => (item.comment = e.target.value)}
                      type="text"
                      name="comments"
                      value={
                        item?.ApproverComment == null
                          ? null
                          : item.ApproverComment
                      }
                      disabled={false}
                    />
                  </SuiBox>
                )}
              </SuiBox>
            </SuiTypography>
          ),
          createddate: <RowItem text={item.CreatedDate} />,
          action: (
            <SuiTypography
              component="a"
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              hidden={item.RequestStatus == "NEW" ? false : true}
            >
              <SuiTypography color={"error"} onClick={() => {setCancelConfirmation(true); setRequestRef(item.id || item.RequestId)}}>
                <span style={{ fontSize: "15px" }}>Cancel</span>
              </SuiTypography>
            </SuiTypography>
          ),
        }))
      );
    } 
    setLoading(false);
  };

  const fetchApprovalsHistory = async () => {
    if(rows2 && rows2.length == 0){
      setLoading(true);
      setRows2([]);
      let res = await ApprovalProvider.getRequestorApprovals();
  
      if (res.length > 0) {
        setRows2(
          res.map((item) => ({
            requester: <RowItem text={item.RequesterUserPrincipalName} />,
            approver: <RowItem text={item.ApproverUserPrincipalName} />,
            type: <RowItem text={item.RequestType} />,
            description: <RowItem text={item.RequestDescription} />,
            role: <Function job="Manager" org={item.ApproverUserPrincipalName} />,
            status: (
              <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                <SuiBox display="flex" flexDirection="column">
                  <SuiTypography
                    fontWeight={item.RequestStatus == "NEW" ? "bold" : "normal"}
                    fontSize={item.RequestStatus == "NEW" ? "15px" : "normal"}
                    variant="caption"
                    color="secondary"
                  >
                    {item.RequestStatus}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            ),
            comments: (
              <SuiTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                <SuiBox pt={0} px={0}>
                  {item.ApproverComment != null ? (
                    <label
                      style={{ color: "#8392ab" }}
                      onChange={(e) => (item.comment = e.target.value)}
                      value={
                        item?.ApproverComment == null
                          ? null
                          : item.ApproverComment
                      }
                    >
                      {item.ApproverComment}
                    </label>
                  ) : (
                    <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
                      <SuiInput
                        onChange={(e) => (item.comment = e.target.value)}
                        type="text"
                        name="comments"
                        value={
                          item?.ApproverComment == null
                            ? null
                            : item.ApproverComment
                        }
                        disabled={true}
                      />
                    </SuiBox>
                  )}
                </SuiBox>
              </SuiTypography>
            ),
          }))
        );
      }
      setLoading(false)
    }  
  };

  function RowItem({ text }) {
    return (
      <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="secondary">
            {text}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    );
  }

  function Function({ job, org }) {
    return (
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </SuiTypography>
        <SuiTypography variant="caption" color="secondary">
          {org}
        </SuiTypography>
      </SuiBox>
    );
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={loading ? "Loader" : "Loader-Result"}>
      {declineConfirmation && (
          <Modal>
            <SuiBox
              alignItems="center"
              textAlign="center"
              display={"flex"}
              flexDirection="column"
            >
              
              <SuiTypography fontWeight="bold" variant="h5">
                Decline Request Confirmation
              </SuiTypography>
              <SuiTypography fontWeight="light" variant="h6">
                Are you sure you want to reject this request?
              </SuiTypography>
              <SuiBox width="100%" mt={3} container>
                <SuiInput
                  onChange={(e) => comment.current = e.target.value}
                  placeholder="Leave a comment (optional)"
                  type="text"
                  name="comments"
                  disabled={false}
                />
                </SuiBox>
                {confirmationMessage && <SuiBox width="60%">
                  <SuiTypography color="error" sx={{fontSize: '.85rem'}} >{confirmationMessage}</SuiTypography>
                </SuiBox>}
                <BeatLoader color={'#D0021B'} loading={confirmationLoading} size={10} />
              <SuiBox mt={3} display={"flex"} gap={2} container>
                <SuiButton
                  onClick={() => {
                    Rejected();
                  }}
                  sx={{ padding: ".5rem 3rem" }}
                  color="success"
                  variant="gradient"
                  disabled={confirmationLoading}
                >
                  Yes
                </SuiButton>
                <SuiButton
                  onClick={() => {
                    setDeclineConfirmation(false); setRequestRef(null);
                  }}
                  sx={{ padding: ".5rem 3rem" }}
                  color="error"
                  variant="gradient"
                  disabled={confirmationLoading}
                >
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Modal>
        )}
        {cancelConfirmation && (
          <Modal>
            <SuiBox
              alignItems="center"
              textAlign="center"
              display={"flex"}
              flexDirection="column"
            >
              
              <SuiTypography fontWeight="bold" variant="h5">
                Cancel Request Confirmation
              </SuiTypography>
              <SuiTypography fontWeight="light" variant="h6">
                Are you sure you want to cancel this request?
              </SuiTypography>
              <SuiBox width="100%" mt={3} container>
                <SuiInput
                  onChange={(e) => comment.current = e.target.value}
                  placeholder="Leave a comment (optional)"
                  type="text"
                  name="comments"
                  disabled={false}
                />
                </SuiBox>
                {confirmationMessage && <SuiBox width="60%">
                  <SuiTypography color="error" sx={{fontSize: '.85rem'}} >{confirmationMessage}</SuiTypography>
                </SuiBox>}
                <BeatLoader color={'#D0021B'} loading={confirmationLoading} size={10} />
              <SuiBox mt={3} display={"flex"} gap={2} container>
                <SuiButton
                  onClick={() => {
                    Cancelled();
                  }}
                  sx={{ padding: ".5rem 3rem" }}
                  color="success"
                  variant="gradient"
                  disabled={confirmationLoading}
                >
                  Yes
                </SuiButton>
                <SuiButton
                  onClick={() => {
                    setCancelConfirmation(false); setRequestRef(null);
                  }}
                  sx={{ padding: ".5rem 3rem" }}
                  color="error"
                  variant="gradient"
                  disabled={confirmationLoading}
                >
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Modal>
        )}
      {
        loading ? <BeatLoader color={'#D0021B'} loading={loading} size={25} /> :
          <DashboardLayout>
            <DashboardNavbar />
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  style={{ background: `${hover}` }}
                  onMouseEnter={() => setHover("#17a2b8")}
                  onMouseLeave={() => setHover("#17a2b8")}
                >
                  <Tab label="Pending Incoming Requests" {...a11yProps(0)} />
                  <Tab onClick={() => fetchMyRequests()} label="My Requests" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <SuiBox py={3}>
                  <SuiBox mb={3}>
                    <Card>
                      <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SuiTypography variant="h6">My Workflow</SuiTypography>
                      </SuiBox>
                      <SuiBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({
                                borders: { borderWidth, borderColor },
                              }) => `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >
                        <Table columns={columns} rows={rows} />
                      </SuiBox>
                    </Card>
                  </SuiBox>
                </SuiBox>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <SuiBox py={3}>
                  <SuiBox mb={3}>
                    <Card>
                      <SuiBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                      >
                        <SuiTypography variant="h6">My Request History</SuiTypography>
                      </SuiBox>
                      <SuiBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({
                                borders: { borderWidth, borderColor },
                              }) => `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >
                        <Table columns={columns2} rows={rows2} />
                      </SuiBox>
                    </Card>
                  </SuiBox>
                </SuiBox>
              </TabPanel>
            </Box>
            <Footer />
          </DashboardLayout>
      }
    </div>
  );
}

export default Approvals;
