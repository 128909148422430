import axios from 'axios'

class UsersProvider {

  static async getUsersSearch({employeeNumber, userPrincipalName, heirarchyLevel = 1}) {
    let results = []
    let url = process.env.REACT_APP_AWS_API + `/GetUserWithManagersAndSubordinates?`;
    employeeNumber ? url += `EmployeeNumber=${employeeNumber}` : '';
    !employeeNumber && userPrincipalName ? url += `UserPrincipalName=${userPrincipalName}` : '';
    heirarchyLevel ? url += `&MaxUserHierarchyLevel=${heirarchyLevel}` : '&MaxUserHierarchyLevel=1';
    
    await axios.get(url,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
    }})
      .then(res => {
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  static async getUserRequests(limit) {
       try {
        const res = await axios.get(process.env.REACT_APP_AWS_API + `/GetUserRequests?Limit=${limit || 25}`, 
          {headers: {
            'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY,
            'userprincipalname': localStorage.getItem("userPrincipalName"),
            'accesstoken': localStorage.getItem("MSAuthToken")
          }})

        if (res.data.length > 0) {
            return res.data;
        }
    } catch (e) {
        console.error('Error:', e);
        return { status: e?.res?.status };
    }
  }

  static async getUserByEmployeeNumber(employeeNumber) {
    let results = null;
    let url = process.env.REACT_APP_AWS_API + `/GetUserByEmployeeNumber?EmployeeNumber=${employeeNumber}`;
    
    await axios.get(url,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
    }})
      .then(res => {
        if (res.data) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results;
  }

  static async getUserHierarchyByEmployeeNumber({employeeNumber}) {
    let results = null;
    let url = process.env.REACT_APP_AWS_API + `/GetUserHierarchyByEmployeeNumber?EmployeeNumber=${employeeNumber}`;
    
    await axios.get(url,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
    }})
      .then(res => {
        if (res.data) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results;
  }

  static async getUserMovementHistory({employeeNumber, limit}) {
    let results = []
    let url = process.env.REACT_APP_AWS_API + `/GetUserHeirachy_History?`;
    employeeNumber ? url += `EmployeeNumber=${employeeNumber}` : '';
    limit ? url += `&Limit=${limit}` : '';
    
    await axios.get(url,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
    }})
      .then(res => {
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results;
  }
  
  static async sendUserSession(userPrincipalName, accessToken) {
    let result = null;
    let requestObject = {
      UserPrincipalName: userPrincipalName,
      AccessToken: accessToken
    }
    await axios.post(process.env.REACT_APP_AWS_API + '/CreateUserSession', requestObject, 
    {headers: {
      'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
  }})
      .then(res => {
        if (res && res.data) {
          result = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      })
      return result;
  }

  static async getUsersByRole({ roleName }) {
    let results = []
    let url = process.env.REACT_APP_AWS_API + `/GetUsersByRoleAndApplication?Role=${roleName}`;
    
    await axios.get(url,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
    }})
      .then(res => {
        if (res.data.length > 0) {
          results = res.data;
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }
}

export default UsersProvider;