import { useState, useEffect } from "react";
import "../profileInfoPopup/Popup.css";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { IconButton } from "@mui/material";
// import { useSnackbar } from 'notistack';
import ProfileProvider from "../../../../providers/ProfileProvider";
import { CloseButton } from "react-bootstrap";
import { number as numberRegex } from "../../../../helpers/regex";

function Popup(props) {
  const [editNumber, setEditNumber] = useState("");
  let number = localStorage.getItem("mobilePhone");
  // const {enqueueSnackbar, closeSnackbar  } = useSnackbar();

  useEffect(() => {
    setEditNumber(localStorage.getItem("mobilePhone"));
  }, [props.trigger]);

  const UpdateMobileNumber = async () => {
    if ((!numberRegex.test(editNumber))) {
      // return enqueueSnackbar("Invalid mobile number, only allowed numbers.");
    }

    let res = await ProfileProvider.updateMobileNumber(editNumber);
    if (res == "success") {
      localStorage.setItem("mobilePhone", editNumber);
      // enqueueSnackbar("Mobile number updated successfully!");
      props.setTrigger(false);
      window.location.reload();
    } else {
      enqueueSnackbar("Failed to update mobile number!");
    }
  };

  const renderHeader = (
    <div>
      <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
        <SuiBox mr={5} pt={0} px={0}>
          <IconButton
            onClick={() => {
              props.setTrigger(false);
            }}
          >
            {" "}
            <CloseButton />
          </IconButton>
        </SuiBox>
        <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
          <h4 style={{ textAlign: "center" }}>Update Mobile Number</h4>
        </SuiBox>
      </SuiBox>
    </div>
  );
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return props.trigger ? (
    <div>
      <div className="popup">
        <div className="container">
          <div className="popup-header">{renderHeader}</div>
          <div className="popup-inner">
            <SuiBox mr={5} pt={0} px={0}>
              <form className="form-group">
                <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
                  <h6>
                    <label for="mobilePhone">Mobile Phone:</label>
                  </h6>
                </SuiBox>
                <SuiBox pt={0} px={0}>
                  <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
                    <SuiInput
                      type="text"
                      value={editNumber}
                      onChange={(e) => setEditNumber(e.target.value)}
                      name="mobilePhone"
                    ></SuiInput>
                  </SuiBox>
                </SuiBox>
              </form>
            </SuiBox>
          </div>

          <div className="popup-footer">
            <SuiButton
              onClick={() => UpdateMobileNumber()}
              disabled={editNumber == number ? true : false}
              className="save-btn"
            >
              Save
            </SuiButton>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default Popup;