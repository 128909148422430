import { React, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph, updateBusinessPhone } from "./graph";
import { callMsGraphProfilePicture } from "./graphProfilePicture";
import Button from "react-bootstrap/Button";
import "./styles/App.scss";
import routes from "./routes";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Redirect,
  Switch,
} from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import RoleIdProvider from "./providers/RoleIdProvider";
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import Sidenav from "examples/Sidenav";
import Requestor from "examples/Requestor";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import {
  useSoftUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import brand from "assets/images/logo-ct.png";


const ProfileContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      });
  }

  return (
    <>
      <h5 className="card-title">Welcome {accounts[0].name}</h5>
      {graphData ? (
        <ProfileData />
      ) : (
        <Button variant="secondary" onClick={RequestProfileData}>
          Request Profile Information
        </Button>
      )}
    </>
  );
};

const handleConfiguratorOpen = () =>
  setOpenConfigurator(dispatch, !openConfigurator);

const configsButton = (
  <SuiBox
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="3.5rem"
    height="3.5rem"
    bgColor="white"
    shadow="sm"
    borderRadius="50%"
    position="fixed"
    right="2rem"
    bottom="2rem"
    zIndex={99}
    color="dark"
    sx={{ cursor: "pointer" }}
    onClick={handleConfiguratorOpen}
  >
    <Icon fontSize="default" color="inherit">
      settings
    </Icon>
  </SuiBox>
);

const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }
    if (route.route) {
      return (
        <Route
          exact
          path={route.route}
          component={route.component}
          key={route.key}
        />
      );
    }

    return null;
  });

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
export default function App() {
  const isAuthenticated = useIsAuthenticated();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [myRoutes, setMyRoutes] = useState([]);
  const { pathname } = useLocation();

  const getMyRoutes = async() => {
    // if((localStorage.getItem('roleId') === null || localStorage.getItem('roleName') === null) && isAuthenticated){
    //   const roleIdResponse = await RoleIdProvider.getRole(localStorage.getItem('userPrincipalName'));
    //   if(roleIdResponse.length > 0) {
    //     localStorage.setItem("roleId", roleIdResponse[0]?.RoleId);
    //     localStorage.setItem("roleName", roleIdResponse[0]?.Role);
    //   }
    // }
    setMyRoutes(routes);
  }

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(async () => {
    getMyRoutes();
  }, [localStorage.getItem('userPrincipalName')]);

  return (
    <>
      {isAuthenticated ? (
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <>
              {myRoutes && <Sidenav
                color={sidenavColor}
                brand={brand}
                //brandName="Rewardsco Portal"
                routes={routes(localStorage.getItem('roleId'))}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              }
              <Requestor />
              <Configurator />
              {/* {configsButton} */}
            </>
            {myRoutes && <Switch>
              {getRoutes(routes(localStorage.getItem('roleId')))}
              <Redirect from="*" to="/profile" />
            </Switch>}
          </ThemeProvider>
        </Router>
      ) : (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <>
            <CoverLayout
              title="Welcome back"
              description="Sign in to Microsoft to get connected..."
              image={curved9}
            >
              <SuiBox component="form" role="form">
                <SuiBox mt={4} mb={1}>
                  <PageLayout></PageLayout>
                </SuiBox>
                <SuiBox mt={3} textAlign="center">
                  <SuiTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    Trouble signing in?{" "}
                  </SuiTypography>
                  <SuiTypography>
                    <SuiTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      Contact{" "}
                      <SuiTypography
                        component={Link}
                        to={{
                          pathname:
                            "https://rewardsco.atlassian.net/servicedesk/customer/portal/9",
                        }}
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        target="_blank"
                      >
                        Rewardsco Service Desk
                      </SuiTypography>
                    </SuiTypography>
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </CoverLayout>
          </>
        </ThemeProvider>
      )}
    </>
  );
}
