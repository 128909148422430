import React, { useState, useEffect } from 'react';
import Dashboard from "layouts/dashboard";
import AppCenter from "layouts/app-center";
import Profile from "layouts/profile";
import Notifications from "layouts/notifications";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupsIcon from '@mui/icons-material/Groups';
import Approvals from "layouts/approvals";
import ApprovalIcon from '@mui/icons-material/Approval';
import TeamMembers from "layouts/team-members";
import TeamManagement from "layouts/team-management";
import Routing from "./static/configs/routingRoleConfig.json"
import { number } from './helpers/regex';

const componentMap = {
  "Dashboard": Dashboard,
  "TeamManagement": TeamManagement,
  "Notifications": Notifications,
  "Profile": Profile,
  "AppCenter": AppCenter,
  "Approvals": Approvals,
  "TeamMembers": TeamMembers,
};

const iconMap = {
  "Shop": <Shop size="12px" />,
  "Office": <Office size="12px" />,
  "Document": <Document size="12px" />,
  "SpaceShip": <SpaceShip size="12px" />,
  "CustomerSupport": <CustomerSupport size="12px" />,
  "NotificationsIcon": <NotificationsIcon size="12px" />,
  "GroupsIcon": <GroupsIcon size="12px" />,
  "ApprovalIcon": <ApprovalIcon size="12px" />,
};

const roleDictionary = [
  {key: 1, value: 'Agent'},
  {key: 2, value: 'Junior Manager'},
  {key: 3, value: 'General manager'},
  {key: 4, value: 'Head Of Sales'},
  {key: 5, value: 'Director'},
  {key: 6, value: 'Trainer'},
  {key: 7, value: 'Admin'},
  {key: 8, value: 'Support'},
  {key: 9, value: 'Facilities'},
  {key: 11, value: 'Back Office'}
];

const routes = () => {
  const roleName = localStorage.getItem('roleName');
  
  let roleConfig = Routing.find(obj => {
    return obj.name === roleName;
  })

  if (roleConfig === undefined){
    roleConfig = Routing.find(obj => {
      return obj.name === 'Agent';
    })
  }

  let tempRoutes = roleConfig.settings.menu_items.map((item) => ({
    ...item,
    "icon": renderIcon(item.icon),
    "component": renderComponent(item.component)
  }))

  return tempRoutes

}

function renderComponent(componentReference) {
  const component = componentMap[componentReference];

  if (component) {
    return component;
  } else {
    return null;
  }
}

function renderIcon(iconReference) {
  const component = iconMap[iconReference];
  if (component) {
    return component;
  } else {
    
    return null;
  }
}

export default routes;
