import { useEffect, useState, useCallback  } from "react";
import React from 'react';
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import SuiInput from "components/SuiInput";
import MovementReasonsSelect from "../components/MovementReasonsSelect";
import CampaignProvider from "../../../providers/CampaignProvider";
import UsersProvider from "../../../providers/UsersProvider";
import { useForm } from 'react-hook-form';
import ActionRequestButtons from "../components/ActionRequestButtons";
import requesterErrorMessages from '../../../static/messages/errorMessages.json';
import ErrorMessage from "../components/ErrorMessage";


export const UserToRemove = React.memo(({ setApiRequestObj }) => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue  } = useForm();
  const [roleNameOptions] = useState([
    {key: 1, value: 'Agent'},
    // {key: 2, value: 'Junior Manager'},
    // {key: 3, value: 'General manager'}
  ]);
  const [errorMessages, setErrorMessages] = useState(null);
  const [userRoleName, setUserRoleName] = useState('');
  const [userOptions, setUserOptions] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [movementReasonId, setMovementReasonId] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);

  

  const onSubmit = () => {  
    if(movementReasonId && selectedUser && localStorage.getItem("EmployeeNumber")){
      setApiRequestObj({
        RequestType: "EMPLOYEES OFFBOARDING",
        RequestDescription: "portal-request",
        CreatedBy: localStorage.getItem("UserName") || null,
        RequesterUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        ApproverUserEmployeeNumbers: localStorage.getItem("EmployeeNumber"),
        UserHierarchyMovementReasonId: movementReasonId,
        OffboardEmployeeNumbers: [selectedUser],
        EffectiveDate: null
      })      
    } 
  };
  const handleFormSubmit = handleSubmit(onSubmit);

  useEffect(() => {
    setErrorMessages(requesterErrorMessages['teamManagement'].requests['userToRemove']);
  }, [])

  const getUsers = async(roleName) => {
    console.log('roleName:', roleName)
    setFetchingData(true);
    const users = await UsersProvider.getUsersByRole({ roleName: roleName });

    let options = users.map((user) => ({
      value: user.EmployeeNumber	,
      label: `${user.FullName} ( ${user.EmployeeNumber} )`
    }));

    setFetchingData(false);
    setUserOptions(options);
  };
  
  
  return (
    <>
    {errorMessages && <>
      <SuiBox mt={4} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">User Selection</SuiTypography>
        <SuiBox position="relative">
          <Select
            key={`roleName`}
            id={`roleName`}
            register={register('roleName', errorMessages['roleName'])}
            options={roleNameOptions.map((role) => ({
                  value: role.value,
                  label: `${role.value}`
            }))}
            placeholder={`Select the user's role you want to offboard`}
            onChange={(e) => {setUserRoleName(e.value);  setValue('roleName', e.value); getUsers(e.value);}}
            isSearchable={false}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['roleName'] && <p className="error msg pad">{errorMessages['roleName']['required']}</p>}
        <SuiBox mt={1} position="relative">
          <Select
            key={`userOptions`}
            id={`userOptions`}
            register={register('userOptions', errorMessages['userOptions'])}
            isLoading={fetchingData}
            isDisabled={fetchingData}
            options={userOptions || []}
            placeholder={`Select a User`}
            onChange={(e) => {setSelectedUser(e.value); setValue('userOptions', e.value);}}
            isSearchable={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['userOptions'] && <p className="error msg pad">{errorMessages['userOptions']['required']}</p>}

      </SuiBox>

      <MovementReasonsSelect
        requestType={'offboarding'} 
        setReasonId={setMovementReasonId} 
        register={register}
        errorMessages={errorMessages}
        errors={errors} 
        setValue={setValue}
       />
      <ActionRequestButtons onSubmit={handleFormSubmit}/>
    </>}
    </>
  )
});
