import { useEffect, useState } from "react";
import React from 'react';
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import SuiInput from "components/SuiInput";
import MovementReasonsSelect from "../components/MovementReasonsSelect";
import CampaignProvider from "../../../providers/CampaignProvider";
import UsersProvider from "../../../providers/UsersProvider";
import { useForm } from 'react-hook-form';
import ActionRequestButtons from "../components/ActionRequestButtons";
import requesterErrorMessages from '../../../static/messages/errorMessages.json';
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import useAlert from "../../../common/useAlert";


import ErrorMessage from "../components/ErrorMessage";


export const UsersMovement = React.memo(({ preloadData, setApiRequestObj }) => {
  console.log('preloadData:',  preloadData)

  const { register, handleSubmit, formState: { errors }, getValues, setValue  } = useForm();
  const { alert } = useAlert();

  const [managerCampaigns, setManagerCampaigns] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [subsetCampaigns, setSubsetCampaigns] = useState([]);
  const [errorMessages, setErrorMessages] = useState(null);
  const [newManager, setNewManager] = useState(null);
  const [selectedParentCampaignObj, setSelectedParentCampaignObj] = useState(null);
  const [selectedSubsetCampaignCode, setSelectedSubsetCampaignCode] = useState(null);
  const [movementReasonId, setMovementReasonId] = useState(null);
  const [usersSelectedForMovement, setUsersSelectedForMovement] = useState(null);
  const requestor = preloadData?.Requestor || null;

  //DISPLAY AUTH ERROR MESSAGE
  if(preloadData && preloadData.RequestStatus !== "NEW"){
    alert({
      icon:'error',
      title: 'This request has already been actioned',
    });
   
    // setTimeout(() =>  window.location.reload(), 2000);

    return;
  }

  const getManagerCampaigns = async(manager) => {
    const campaigns = await CampaignProvider.getManagerUserCampaigns(manager.EmployeeNumber);
    console.log('campaigns:', campaigns)
    setManagerCampaigns(campaigns);

    let subCampaigns = [];
    for (let index = 0; index < campaigns.length; index++) {
      const parentCampaign = campaigns[index];
      subCampaigns.push(...parentCampaign.SubsetCampaigns);
    }
    console.log('subCampaigns:', subCampaigns)
    if (subCampaigns && subCampaigns.length > 0) {
      setSubsetCampaigns(subCampaigns);
    }
  }

  const onSubmit = () => {
    if(preloadData?.RequestId && localStorage.getItem("EmployeeNumber")){
      setApiRequestObj({
        RequestType: 'APPROVE TEAM MANAGEMENT',
        RequestDescription: "portal-request",
        CreatedBy: localStorage.getItem("UserName") || null,
        ApproverUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        ApprovedRequestId: preloadData.RequestId,
        EmployeeMovements: selectedUsers
      })
    }
  };
  const handleFormSubmit = handleSubmit(onSubmit);

  const updateUserListCampaignAssignment = (employeeNumber, campaignCode) => {  
    let arr = selectedUsers;
    const employee = arr.find(emp => emp.EmployeeNumber === employeeNumber);
    if (employee) {
      employee.CampaignCodes = [campaignCode];
    }
    setSelectedUsers(arr);
  };
  useEffect(async() => {
    //GET NEW MANAGER OBJECT NOT YOU!!!
    const manager = await UsersProvider.getUserByEmployeeNumber(preloadData?.RequestData?.ManagerUserEmployeeNumber);
    console.log('MANAGER:', manager)
    setNewManager(manager);
    await getManagerCampaigns(manager);

    if(preloadData?.RequestData?.EmployeeMovements){
      setSelectedUsers(preloadData.RequestData.EmployeeMovements);
    }
    setErrorMessages(requesterErrorMessages['teamManagement'].requests['userMovement']);
  }, [])
  const customStyles = {
    control: base => ({
      ...base,
      height: 35,
      minHeight: 35
    })
  };
  if(!preloadData){
    return (
      <>
      <SuiBox display={'flex'} gap={2} alignItems='center' >
        <SuiTypography
            component="span"
            variant="body2"
            color={"error"}
          >
            Escalations must be actioned from the Approvals tab:
          </SuiTypography>
        <a href="/approvals">
          <SuiTypography
            component="span"
            variant="body2"
            color={"dark"}
            mb
          >
            Approvals Page
            &nbsp;
            <Icon>link</Icon>
          </SuiTypography>
        </a>
        </SuiBox>
      </>
    )
  }
  
  return (
    <>
    {errorMessages && <>
      <SuiBox mt={4} mb={2} lineHeight={1}>
        <SuiTypography sx={{paddingBottom: '0.5rem'}} variant="h6">Request being actioned for</SuiTypography>
        <SuiInput
          label={`User`}
          id={`userEscalationRef`}
          name={`userEscalationRef`}
          disabled={true}
          value={`${requestor?.FullName} ( ${requestor?.EmployeeNumber} )`}
        />
        {/* <p className="error msg pad">{`something`}</p> */}
      </SuiBox>
      <SuiBox mt={4} mb={2} lineHeight={1}>
        <SuiTypography sx={{paddingBottom: '0.5rem'}} variant="h6">New Manager : </SuiTypography>
        <SuiInput
          label={`User`}
          id={`userEscalationRef`}
          name={`userEscalationRef`}
          disabled={true}
          value={`${newManager?.FullName} ( ${newManager?.EmployeeNumber} )`}
        />
        {/* <p className="error msg pad">{`something`}</p> */}
      </SuiBox>
      {/* <SuiBox mt={4} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">Campaign Assignment</SuiTypography>
        <SuiBox position="relative">
          <Select
            key={`parentCampaignCode`}
            id={`parentCampaignCode`}
            register={register('parentCampaignCode', errorMessages['parentCampaignCode'])}
            options={managerCampaigns.map((campaign) => ({
                  value: campaign.Code,
                  label: `${campaign.Name} ( ${campaign.Code} )`
            }))}
            // value={selectedParentCampaignObj}
            placeholder={`Search campaigns by Campaign Code`}
            onChange={(e) => {setSelectedParentCampaignObj(e); setSelectedSubsetCampaignCode(null); setValue('subsetCampaignCode', undefined); setValue('parentCampaignCode', e);}}
            isSearchable={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['parentCampaignCode'] && <p className="error msg pad">{errorMessages['parentCampaignCode']['required']}</p>}
        <SuiBox mt={1} position="relative">
          <Select
            key={`subsetCampaignCode`}
            id={`subsetCampaignCode`}
            register={register('subsetCampaignCode', errorMessages['subsetCampaignCode'])}
            options={setDiallerCampaigns()}
            value={selectedSubsetCampaignCode || ''}
            placeholder={`Select a Subset/Dialer Campaign`}
            onChange={(e) => {setSelectedSubsetCampaignCode(e); setValue('subsetCampaignCode', e.value);}}
            isSearchable={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['subsetCampaignCode'] && <p className="error msg pad">{errorMessages['subsetCampaignCode']['required']}</p>}

      </SuiBox> */}

            {/* ALLOW THE USER TO SELECT A CAMPAIGN AND ASSIGN TO ALL THE USRES THEY HAVE SELECT       */}
            <SuiBox mt={6} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">User Campaign Assignment</SuiTypography>
        <SuiBox px={3}>
        {selectedUsers && selectedUsers.length > 0 && selectedUsers.map((user) => {
            return (
              <SuiBox key={user.EmployeeNumber} display="grid" gridTemplateColumns=".6fr 1fr" mt={2} alignItems="center"> 
                <SuiTypography sx={{fontSize: ".85rem"}} color="dark">{user.Name} ( {user.EmployeeNumber} )</SuiTypography>
                <Select
                  key={`campaignAssignment`}
                  id={`campaignAssignment`}
                  register={register('campaignAssignment', errorMessages['campaignAssignment'])}
                  // isLoading={fetchingData}
                  // isDisabled={fetchingData}
                  // styles={customStyles} 
                  options={subsetCampaigns.map((campaign) => ({
                    value: campaign.Code,
                    label: `${campaign.Code}`
                  })) || []}
                  placeholder={`Select campaigns`}
                  onChange={(e) => {updateUserListCampaignAssignment(user.EmployeeNumber, e.value); setValue('campaignAssignment', e.value);}}
                  isSearchable={true}
                  classNamePrefix="custom-select"
                  className="select-control select-one select-with-html"
                />
                </SuiBox> 
            )
          })}
        </SuiBox>
        {errors['campaignAssignment'] && <p className="error msg pad">{errorMessages['campaignAssignment']['required']}</p>}

      </SuiBox>
      <ActionRequestButtons onSubmit={handleFormSubmit}/>
    </>}
    </>
  )
});
