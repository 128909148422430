import { useEffect, useState, useCallback, useRef  } from "react";
import React from 'react';
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import SuiInput from "components/SuiInput";
import MovementReasonsSelect from "../components/MovementReasonsSelect";
import CampaignProvider from "../../../providers/CampaignProvider";
import UsersProvider from "../../../providers/UsersProvider";
import { useForm } from 'react-hook-form';
import ActionRequestButtons from "../components/ActionRequestButtons";
import requesterErrorMessages from '../../../static/messages/errorMessages.json';
import SuiButton from "components/SuiButton";
import ErrorMessage from "../components/ErrorMessage";
// import { Modal } from "@mui/material";
import FileUpload from "../../../components/User/FileUpload.jsx";
import Modal from '../../../components/Modals/ModalSkeleton';
import templates from '../../../static/templates/fileUpload.json'
import { AiOutlineClose } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import Card from "@mui/material/Card";
import { 
  username, 
  alphaAndNumericWithSpaces, 
  rewardscoEmail, 
  rewardscoAgentEmail, 
  alphaWithSpaces, 
  mobile, 
  number } from "../../../helpers/regex";
import { CSVLink } from 'react-csv';
import { ImDownload2 } from 'react-icons/im';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { isEmpty } from "lodash";
import { has, uniq } from 'lodash';






export const UsersCreation = React.memo(({ setApiRequestObj }) => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue  } = useForm();
  const [roleNameOptions] = useState([
    {key: 1, value: 'Agent'},
    {key: 2, value: 'Junior Manager'},
    // {key: 3, value: 'General Manager'},
    // {key: 4, value: 'Trainer'},
  ]);
  const [createUserOptions] = useState([
    {key: 1, value: 'File-Upload'}
  ]);
  const [errorMessages, setErrorMessages] = useState(null);
  const [userRoleName, setUserRoleName] = useState('');
  const [userOptions, setUserOptions] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [movementReasonId, setMovementReasonId] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
  const [invalidRecords, setInvalidRecords] = useState([]);
  const [onboardingUsers, setOnboardingUsers] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInvalidRecords, setShowInvalidRecords] = useState(false);
  const [managerCampaigns, setManagerCampaigns] = useState([]);

  let managerProfile = useRef();


  const approverFileTemplate = templates.userOnboard[userRoleName] || null;
  const Fields = [
    {
      name: 'UserName',
      regex: username,
      required: true
    },
    {
      name: 'Name',
      regex: alphaWithSpaces,
      required: true
    },
    {
      name: 'Surname',
      regex: alphaWithSpaces,
      required: true
    },
    {
      name: 'UserPrincipalName',
      regex: userRoleName === 'Agent' ? rewardscoAgentEmail : rewardscoEmail,
      required: true
    },
    {
      name: 'MobileNumber',
      regex: mobile,
      required: false
    },
    {
      name: 'EmployeeNumber',
      regex: number,
      required: true
    },
    {
      name: 'Role',
      options: ['Agent', 'Junior Manager', 'General Manager'],
      ruleMatch: userRoleName,
      required: true
    },
    {
      name: 'ManagerUserPrincipalName',
      regex: rewardscoEmail,
      required: true
    },
    {
      name: 'ManagerEmployeeNumber',
      regex: number,
      required: true
    },
    {
      name: 'CampaignCodes',
      regex: alphaAndNumericWithSpaces,
      required: false
    },
  ];
  

  const onSubmit = () => {  

    if(movementReasonId && localStorage.getItem("EmployeeNumber")){
      setApiRequestObj({
        RequestType: "NEW EMPLOYEES ONBOARDING",
        RequestDescription: "portal-request",
        CreatedBy: localStorage.getItem("UserName") || null,
        RequesterUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        ApproverUserEmployeeNumbers: [localStorage.getItem("EmployeeNumber")],
        UserHierarchyMovementReasonId: movementReasonId,
        EffectiveDate: null,
        EmployeeMovements: onboardingUsers.map((user) => {
          const diallerUserName = user?.UserName ? user.UserName.replace('Rewardsco\\','') : null;

          return ({
            UserName: user.UserName,
            DiallerUserName: diallerUserName,
            FullName: user.Name + ' ' + user.Surname,
            UserPrincipalName: user.UserPrincipalName ,
            MobileNumber: user?.MobileNumber || null,
            EmployeeNumber: user.EmployeeNumber,
            ManagerUserEmployeeNumber: user.ManagerEmployeeNumber,
            Application: "Molo Sales",
            Role: user.Role,
            CampaignCodes: user?.CampaignCodes || null
          })
        })
      })      
    } 
  };
  const handleFormSubmit = handleSubmit(onSubmit);

  useEffect(() => {
    setErrorMessages(requesterErrorMessages['teamManagement'].requests['userCreation']);
  }, [])

  const getUsers = async(roleName) => {
    setFetchingData(true);
    const users = await UsersProvider.getUsersByRole({ roleName: roleName });

    let options = users.map((user) => ({
      value: user.EmployeeNumber	,
      label: `${user.FullName} ( ${user.EmployeeNumber} )`
    }));

    setFetchingData(false);
    setUserOptions(options);
  };

  function compareArrays(array1, array2) {
    let invalidations = [];
    for (let value of array1) {
        if (!array2.includes(value)) {
            invalidations.push({description: `Campaign ${value} does not exist.`});
        }
    }
    return invalidations;
}

  const fieldValidation = async(objects) => {
    let invalidations = [];
    let campaigns = [];
    let managers = [];
    let managerEmpNumbers = [];
    let userEmpNumbers = [];
    let userNames = [];

    setIsLoading(true);

    for (let i = 0; i < objects.length; i++) {      
      const object = objects[i];
      userEmpNumbers.push(object['EmployeeNumber']);

      for (let j = 0; j < Fields.length; j++) {
        const { name, regex, options, ruleMatch, required } = Fields[j];
        if (!object.hasOwnProperty(name)) {
            invalidations.push({description: `Record/Row ${i + 2} => Field ${name} missing.`});
            return invalidations;
        }

        if(isEmpty(object[name]) && required == false){
          continue;
        }

        if (isEmpty(object[name]) || (regex && !regex.test(object[name]))) {
            invalidations.push({description: `Record/Row ${i + 2} => Field ${name} does meet the format requirements.`});
        }

        if(options && !options.includes(object[name])){
          invalidations.push({description: `Record/Row ${i + 2} => Field ${name} must be one the following: ${JSON.stringify(options)}.`});
        }

        if(ruleMatch && ruleMatch !== object[name]){
          invalidations.push({description: `Record/Row ${i + 2} => User ${name} must be ${userRoleName} for an ${userRoleName} Onboard Request.`});
        }

        if(name === 'UserName'){
          const usernameResult = validUsername(object);
          !usernameResult ? 
          invalidations.push({description: `Record/Row ${i + 2} => UserName format invalid for ${userRoleName} user.`}) : null;

          if (!userNames.includes(object[name])) {
            userNames.push(object[name]);
          }else{
            invalidations.push({description: `Record/Row ${i + 2} => Duplicate UserName Record.`})
          }
        }

        if(name === 'UserPrincipalName'){
          const emailResult = validEmail(object);
          !emailResult ? 
          invalidations.push({description: `Record/Row ${i + 2} => Email format invalid for ${userRoleName} user`}) : null;
        }

        if(name === 'CampaignCodes'){
          const campaignsArray = object[name].split(',');
          object[name] = campaignsArray;
          for (let index = 0; index < campaignsArray.length; index++) {
            const campaignCode = campaignsArray[index];
            
            //Check Campaign Actually exists
            if (!campaigns.includes(campaignCode)) {
              campaigns.push(campaignCode);
            }
          }
        }

        if(name === 'ManagerUserPrincipalName'){
          if (!managers.includes(object[name])) {
            managers.push(object[name]);
          }
        }

        if(name === 'ManagerEmployeeNumber'){
          if (!managerEmpNumbers.includes(object[name])) {
            managerEmpNumbers.push(object[name]);
          }
        }
      }
    }

    if(userEmpNumbers.length > 0){
      uniq(userEmpNumbers).length !== userEmpNumbers.length ?
      invalidations.push({description: `Column EmployeeNumber => Duplicate Employee Numbers`}) : null;
    }

    if(invalidations && invalidations.length > 0){ setIsLoading(false); return invalidations };

    //Validate Manager has those campaigns underneath him/her
    if(managers.length > 1){
      invalidations.push({description: `Column ManagerUserPrincipalName => Only 1 manager can be assigned per Onboard request.`});
    }

    if(managerEmpNumbers.length > 1){
      invalidations.push({description: `Column ManagerEmployeeNumber => Only 1 manager employee number can be assigned per Onboard request.`});
    }else{

      try {
        let managerRole = '';
        managerProfile.current = await UsersProvider.getUserByEmployeeNumber(objects[0].ManagerEmployeeNumber);

        if(managerProfile.current){
          managerRole = managerProfile.current.Role;

          objects[0].ManagerUserPrincipalName.toLowerCase() !== managerProfile.current.UserPrincipalName.toLowerCase() ?
          invalidations.push({description: `ManagerUserPrincipalName and ManagerEmployeeNumber do not match the same user.`}) : null;
  
          objects[0].ManagerEmployeeNumber !== managerProfile.current.EmployeeNumber ?
          invalidations.push({description: `ManagerUserPrincipalName and ManagerEmployeeNumber do not match the same user.`}) : null; 
                  
          userRoleName === 'Agent' && !['Trainer', 'Junior Manager'].includes(managerRole) ?
          invalidations.push({description: `Can't assign ${userRoleName} users to ${managerRole}`}) :
          userRoleName === 'Junior Manager' && managerRole !== 'General Manager' ? 
          invalidations.push({description: `Can't assign ${userRoleName} users to ${managerRole}`}) :
          userRoleName === 'General Manager' && managerRole !== 'Head of Sales' ? 
          invalidations.push({description: `Can't assign ${userRoleName} users to ${managerRole}`}) : null;
        }else{
          invalidations.push({description: `Invalid ManagerEmployeeNumber`});
        }

      } catch (error) {
        invalidations.push({description: `Column ManagerEmployeeNumber failed validating manager information. Please retry upload file.`});
      }
    }

    if(invalidations && invalidations.length > 0){ setIsLoading(false); return invalidations };
    
    //Check if campaigns exist then => Check if Manager has that campaign under them
    //IF AGENTS DONT ALLOW CAMPAIGN ASSIGNMENT
    //IF OTHER JM, GM or TRAINER ALLOW
    if(campaigns && campaigns.length > 0 && userRoleName === 'Agent'){
      invalidations.push({description: `Campaign Assignment for agents must happen after creation by assigned JM, please remove campaign assignments`});
      return invalidations;
    }
    if(campaigns && campaigns.length > 0){
      let dbCampaignCodes = [];
      const dbCampaigns = await getCampaigns(objects[0].ManagerEmployeeNumber);

      if(dbCampaigns && dbCampaigns.length > 0 ){
        for (let i = 0; i < dbCampaigns.length; i++) {
          dbCampaignCodes.push(dbCampaigns[i].Code);
        }
      }
     
      let campaignInvalidations = compareArrays(campaigns, dbCampaignCodes);
      invalidations = [...invalidations, ...campaignInvalidations];
    }

    const managerCampaigns = managerProfile.current.Campaigns.map(camp => camp.Code);

    if(managerCampaigns.length > 0){
      for (let value of campaigns) {
        if (!managerCampaigns.includes(value)) {
            invalidations.push({description: `Campaign ${value} is not assigned to that manager.`});
        }
      }
    }

    setIsLoading(false);
    return invalidations; // All objects contain all required properties with valid values
  }

  const getCampaigns = async(employeeNumber) => {
    try {
      const res = await CampaignProvider.getManagerUserCampaigns(employeeNumber);
      if(res.length > 0){
        return res;
      }
    } catch (error) {
      //DO NOTHING FOR NOW
    }
  }

  const fileValidationCheck = async(data) => {
    let invalidations = [];
    setValue('newUsers', undefined);
    setInvalidRecords([]);
    setOnboardingUsers([]);
    setIsValid(false);
    
    //Validate Required Headers
    for (let field of Fields) {
      if (!has(data[0], field.name)) {
        invalidations.push({description: `Invalid file. Missing required header ${field.name}`});
      }
    }

    if(invalidations && invalidations.length > 0){ setInvalidRecords(invalidations); return; }

    // 2) Validate Record Format
    const invalidationsResp = await fieldValidation(data);
    if(invalidationsResp.length > 0){ setInvalidRecords(invalidationsResp); return; }

    setIsValid(true);
    setValue('newUsers', data);
    console.log('ONBOARDING USERS:', data)
    setOnboardingUsers(data);
    // setRequestorObject({'initiateAdd': data});
  }

  const validUsername = (input) => {
    let username = '';

    if(userRoleName === 'Agent'){
      username = 'Rewardsco\\' + (input?.Name && input?.Name[0] ) + 
                 (input?.Surname && input?.Surname.substring(0, 3).toLowerCase()) + 
                 (input?.EmployeeNumber && input?.EmployeeNumber);

    }else{
      username = 'Rewardsco\\' + (input?.Name) + '.' + (input?.Surname);
    }

    return input.UserName === username; 
  }

  const validEmail = (input) => {
    let username = '';
    let email = '';

    if(userRoleName === 'Agent'){
      username = (input?.Name && input?.Name[0] ) + 
                 (input?.Surname && input?.Surname.substring(0, 3).toLowerCase()) + 
                 (input?.EmployeeNumber && input?.EmployeeNumber);
                 
      email = username + '@rewardsco.co.za';
      return email === input.UserPrincipalName; 

    }else{

      username = (input?.Name) + '.' + (input?.Surname);
      return (username + '@rewardsco.co.za' === input.UserPrincipalName) || (username + '@rewards.co.za' === input.UserPrincipalName); 

    }
  }
  
  const resetFileUploadState = () => {
    setIsValid(false);
    setShowInvalidRecords(false);
    setInvalidRecords([]);
    setOpenFileUploadModel(false);
    setOnboardingUsers([]);
  }

  return (
    <>
    {errorMessages && <>
      {openFileUploadModel && <Modal>
        <div>
        <SuiBox mb={1} alignItems="baseline" justifyContent="space-between" display="flex" className="header">
        <SuiTypography pb={2} variant="h6">File Upload</SuiTypography>
        <SuiTypography onClick={() => resetFileUploadState()} gap={1} display="flex" alignItems='center' color="error" variant="h6" >
          <IoIosCloseCircleOutline /> Close
        </SuiTypography>
      </SuiBox>
      <SuiBox mb={1}  className="header">
        {approverFileTemplate && <CSVLink separator=";" filename={`${userRoleName}-onboard-template.csv`} data={approverFileTemplate}>
        <SuiButton color="info"  >
          <ImDownload2 /> &nbsp; Download Template
        </SuiButton>
        </CSVLink>}
      </SuiBox>

      <SuiBox>
        <div className="form-group">
          <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
            <FileUpload
              isLoading={isLoading}
              isValid={isValid}
              invalidRecords={invalidRecords}
              fileValidationCheck={fileValidationCheck}
              handleNextStep={() => console.log('WOW!!!')}
            />
          </SuiBox>
          <SuiTypography color="secondary" sx={{fontSize: ".85rem"}}><b>Note:</b> On uploading a file it will automatically be validated to confirm all provided data is valid.</SuiTypography>

        </div>
      </SuiBox>

      <div className="button-section">
        {invalidRecords.length > 0 && <SuiBox className="error-tag" onClick={() => setShowInvalidRecords(true)}>
          <BiError />
          <SuiTypography color="error" variant="caption">Validation Errors Found</SuiTypography>
        </SuiBox>}
        <button onClick={() => setOpenFileUploadModel(false)} disabled={onboardingUsers?.length == 0} className="btn btn-orange standard">
          Continue
				</button>
      </div>
      {showInvalidRecords && 
      <Card className="file-error-card">
        <SuiBox className='header'>
          <SuiTypography variant="h6">Validation Errors</SuiTypography>
          <AiOutlineClose className="close-btn" onClick={() => {setShowInvalidRecords(false)}}/>
        </SuiBox>
        <SuiBox className="error-content" sx={{ maxHeight: '200px', minWidth: '400px' }} >
          {invalidRecords.length > 0 && invalidRecords.map((record, index) => (
            <SuiTypography key={index} color="error" variant="overline">{record.description}</SuiTypography>
          ))}
        </SuiBox>
      </Card>}
    </div>
      </Modal>}
      <SuiBox mt={4} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">User Selection</SuiTypography>
        <SuiBox position="relative">
          <Select
            key={`roleName`}
            id={`roleName`}
            register={register('roleName', errorMessages['roleName'])}
            options={roleNameOptions.map((role) => ({
                  value: role.value,
                  label: `${role.value}`
            }))}
            placeholder={`Select the user's role you want to offboard`}
            onChange={(e) => {setUserRoleName(e.value);  setValue('roleName', e.value); getUsers(e.value);}}
            isSearchable={false}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['roleName'] && <p className="error msg pad">{errorMessages['roleName']['required']}</p>}
      </SuiBox>
      <SuiBox position="relative">
          <Select
            key={`onboardOption`}
            id={`onboardOption`}
            register={register('newUsers', errorMessages['newUsers'])}
            options={[{
              value: "file-upload",
              label: "Upload a file",
              description: "Select and choose"
            }]}
            isDisabled={!userRoleName}
            placeholder={`Choose option to onboard new users`}
            onChange={(e) => {setOpenFileUploadModel(true);}}
            isSearchable={false}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['roleName'] && <p className="error msg pad">{errorMessages['newUsers']['required']}</p>}
      <SuiBox mt={6} mb={6} lineHeight={1}>
        <SuiTypography variant="h6">New User/s Confirmation</SuiTypography>
        <SuiBox pt={3} px={2} position="relative">
          {onboardingUsers && onboardingUsers.length > 0 ? onboardingUsers.map((user) => (
            <SuiBox px={2} py={1} sx={{backgroundColor: '#75b4db'}} variant="outlined" mb={1}>
              <SuiTypography color="white" fontWeight="bold" sx={{fontSize: ".85rem"}}>{user.Name} {user.Surname} (Emp Num: {user.EmployeeNumber})</SuiTypography>
            </SuiBox>
          ))
          :
          <SuiTypography color="info" fontWeight="bold" sx={{fontSize: ".85rem"}}>No users current confirmed...</SuiTypography>
          }
          
          {/* <SuiButton variant="text" color="info">View Details</SuiButton> */}
        </SuiBox>
      </SuiBox>

      <MovementReasonsSelect
        requestType={'onboarding'} 
        setReasonId={setMovementReasonId} 
        register={register}
        errorMessages={errorMessages}
        errors={errors} 
        setValue={setValue}
       />
      <ActionRequestButtons onSubmit={handleFormSubmit}/>
    </>}
    </>
  )
});
