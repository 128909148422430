export const roleDictionary = [
    {key: 1, value: 'Agent'},
    {key: 2, value: 'Junior Manager'},
    {key: 3, value: 'General manager'},
    {key: 4, value: 'Head Of Sales'},
    {key: 5, value: 'Director'},
    {key: 6, value: 'Training'},
    {key: 7, value: 'Admin'},
    {key: 8, value: 'Support'},
    {key: 9, value: 'Facilities'},
    {key: 11, value: 'Back Office'}
];

const requestTypeDictionary = [
    {
        key: 'usersMovementApproval', 
        title: "Request user movement",
        description: "Approve a request to move a user or team"
    },
    {
        key: 'usersCreation', 
        title: "Request user creation",
        description: "Create a request to create new user or team"
    },
    {
        key: 'userToRemove', 
        title: "Request user removal",
        description: "Create a request to remove a user"
    },
    {
        key: 'userCampaignUpdate', 
        title: "Request agent campaign update",
        description: "Move an agent/s to a new campaign"
    },
    {
        key: 'campaignCreation', 
        title: "Request campaign creation",
        description: "Create a new campaign"
    },
    {
        key: 'campaignEscalation', 
        title: "Agent Campaign Escalation",
        description: "Create a request to resolve the campaign escalation"
    },
    {
        key: 'managerEscalation', 
        title: "Agent Manager Escalation",
        description: "Create a request to resolve the manager escalation"
    },
    {
        key: 'usersMovementRequest', 
        title: "Agent Movement Request",
        description: "Create a request to move a user or team"
    }
];

const requestTypeAccessByRole = [
    {
        role: 'Junior Manager',
        allowedRequests: [ 'userCampaignUpdate', 'usersMovementRequest']
    },
    {
        role: 'General Manager',
        allowedRequests: ['userCampaignUpdate', 'usersMovementRequest']
    },
    {
        role: 'Head Of Sales',
        allowedRequests: []
    },
    {
        role: 'Trainer',
        allowedRequests: ['userCampaignUpdate', 'usersMovementRequest']
    },
    {
        role: 'Dialler',
        allowedRequests: ['campaignCreation']
    },
    {
        role: 'Admin',
        allowedRequests: ['usersCreation']
    }
]

export const GetRequestTypeAccess = ({ roleName }) => {
    // const roleExists = roleDictionary.some(role => role.value === roleName);
    const roleTypeAccess = requestTypeAccessByRole.find(role => role.role === roleName);
    let requestTypes = [];
    if(roleTypeAccess){
        for (let index = 0; index < roleTypeAccess.allowedRequests.length; index++) {
            const request = roleTypeAccess.allowedRequests[index];

            let res = requestTypeDictionary.find(requestT => requestT.key === request);
            requestTypes.push(res);
        }
    }

    return requestTypes;
}