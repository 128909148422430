import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import axios from "axios";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ManagerCard from "./components/ManagerCard";
import CampaignCard from "./components/CampaignCard";
import Header from "layouts/profile/components/Header";
import { callMsGraph } from "../../graph";
import BeatLoader from "react-spinners/BeatLoader";
import Card from "@mui/material/Card";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import ApprovalProvider from "../../providers/ApprovalProvider";
import CampaignProvider from "../../providers/CampaignProvider";
import ManagerProvider from "../../providers/ManagerProvider";
import ProfileProvider from "../../providers/ProfileProvider";
import UsersProvider from "../../providers/UsersProvider";
import RoleIdProvider from "../../providers/RoleIdProvider";

import useAlert from "../../common/useAlert";
import Modal from '../../components/Modals/ModalSkeleton';
import SuiInput from "components/SuiInput";
import Icon from "@mui/material/Icon";

import Approvals from "layouts/approvals";



function Overview() {
  const { alert } = useAlert();
  const [employeeNumber, setEmployeeNumber] = useState('')
  const [roleName, setRoleName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [diallerUserName, setDiallerUserName] = useState('')
  const [userAssignedCampaigns, setUserAssignedCampaigns] = useState([]);
  const [userAssignedManager, setUserAssignedManager] = useState([]);
  const [hideConfirmationCard, setHideConfirmationCard] = useState(false);
  const [disableEscalations, setDisableEscalations] = useState(true);
  const [showCancelEscalationButton, setShowCancelEscalationButton] = useState(false);
  const [displayHelpText, setDisplayHelpText] = useState(false);
  const [showCancellationPopup, setShowCancellationPopup] = useState(false);
  const [approvals, setApprovals] = useState('')
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState({...localStorage});
  const UserPrincipalName = localStorage.getItem("userPrincipalName");
  const [rolePageException, setRolePageException] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  
  const initializeProfileData = async () => {
      const profile = await ProfileProvider.getProfile(graphData.userPrincipalName);

      if((localStorage.getItem('roleId') === null || localStorage.getItem('roleName') === null) && isAuthenticated){
        const roleIdResponse = await RoleIdProvider.getRole(graphData.userPrincipalName);
        if(roleIdResponse.length > 0) {
          localStorage.setItem("roleId", roleIdResponse[0]?.RoleId);
          localStorage.setItem("roleName", roleIdResponse[0]?.Role);
          setRoleName(roleIdResponse[0]?.Role);
        }
      }

      if(profile){
        setMobileNumber(profile.MobileNumber);
        setEmployeeNumber(profile.EmployeeNumber);
        setDiallerUserName(profile.DiallerUserName);
        localStorage.setItem('EmployeeNumber', profile?.EmployeeNumber);
        localStorage.setItem('UserName', profile?.UserName);

      }else{
        alert('Error getting profile data for ' + UserPrincipalName + '. Please contact Rewardsco Support!');
      }
}

  const initialzeGraphdata = async() => {
    if (isAuthenticated) {
      const request = {...loginRequest, account: accounts[0]};
      if (graphData && Object.keys(graphData).length === 0) {

        instance
          .acquireTokenSilent(request)
          .then(async(response) => {
            const userSessionsResults = await UsersProvider.sendUserSession(response.account.username, response.accessToken);
                
            if (userSessionsResults.status === "success") {
              localStorage.setItem("MSAuthToken", response.accessToken);
            }
            callMsGraph(response.accessToken).then((response) => {
              setGraphData(response);
              localStorage.setItem("email", response?.mail);
              localStorage.setItem("title", response?.jobTitle);
              localStorage.setItem("fullName", response?.displayName);
              localStorage.setItem("userPrincipalName", response?.userPrincipalName);
              localStorage.setItem("mobilePhone", response?.mobileNumber);
            });
          })
          .catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
              callMsGraph(response.accessToken).then((response) =>
                setGraphData(response)
              );
            });
          });
      }
    } else {
      localStorage.clear();
    }
  }

  const handleConfirmation = async() => {
    setSubmissionLoading(true);
    const user = await UsersProvider.getUserByEmployeeNumber(localStorage.getItem('EmployeeNumber'));

    if(user){
      let data = {
        UserID: user.Id,
        LogType: "Confirmation",
        LogTrigger: "User Action",
        LogDescription: "Manager and Campaign Confirmation",
        LogData: null,
        AuditedObjectName: null,
        AuditedObjectKey: null,
        CreatedBy: user.UserName
      }
      
      let auditLog= await ApprovalProvider.auditSubmission(data);
      if(auditLog && auditLog?.status === 'success'){
        localStorage.setItem("confirmationValid", true);
      }
    }
    
    setHideConfirmationCard(true);
    setSubmissionLoading(false);
  }

  const getApprovals = async() => {
    let pendingApprovals= await ApprovalProvider.getRequestorApprovals('NEW');
    if(pendingApprovals && pendingApprovals.length > 0){
      for (let i = 0; i < pendingApprovals.length; i++) {
        const item = pendingApprovals[i];
        
        if(item.RequestType.toLowerCase().includes("escalation") && item.RequestStatus === 'NEW'){
          setApprovals([item]);
          setDisableEscalations(true);
          setShowCancelEscalationButton(true);
          return;
        }
      }
    }
    setDisableEscalations(false);

  }

  const cancelEscalation = async() => {
    const requestInfo = await ApprovalProvider.getRequestInformation(approvals[0].RequestId);
    if(requestInfo.RequestStatus !== "NEW"){
      alert({
        icon:'error',
        title: `This request has already been ${requestInfo.RequestStatus}. Please refresh the page.`,
      });
      return;
    }

    setSubmissionLoading(true);
    if(approvals && approvals.length > 0 && approvals[0].RequestType && approvals[0].RequestType.toLowerCase().includes("escalation")){
      const requestStatus = "CANCELLED";
      const response = await ApprovalProvider.updateApproval(approvals[0].RequestId, requestStatus, null);
      if(response.status == 'success'){
        setShowCancellationPopup(false);
        setShowCancelEscalationButton(false);
        await getApprovals();
        alert({
          icon:'success',
          title: 'Pending Escalation Successfully Cancelled. Please Refresh',
        });
      }
    }
    setSubmissionLoading(false);
  }

  const fetchUserManager = async () => {
    let res = await ManagerProvider.getUserManager();
    if (res.length > 0) {
      setUserAssignedManager(res[0])
    }
  }

  useEffect(async() => {
    setLoading(true);

    if (!graphData.fullName && 
        !graphData.email && 
        !graphData.title && 
        !graphData.userPrincipalName) {
      await initialzeGraphdata();
    } 
  }, [])

  useEffect(async() => {
    if(localStorage.getItem("confirmationValid") === "true"){ 
      setHideConfirmationCard(true); 
    }

    if(graphData && Object.keys(graphData).length !== 0){
      await initializeProfileData();
      await fetchUserManager();

      if(localStorage.getItem("roleName") === 'Agent'){
        await getApprovals();
        let campaigns = await CampaignProvider.getAgentUserCampaigns(localStorage.getItem('EmployeeNumber'));
  
        if(campaigns && campaigns.length > 0){
          setUserAssignedCampaigns(campaigns);
        }
      }else if(localStorage.getItem("roleName") === 'Junior Manager' || localStorage.getItem("roleName") === 'General Manager'){
        let campaigns = await CampaignProvider.getManagerUserCampaigns(localStorage.getItem('EmployeeNumber'));
  
        if(campaigns && campaigns.length > 0){
          setUserAssignedCampaigns(campaigns);
        }
      }

      setRolePageException(localStorage.getItem("roleName") === 'Agent' ? true : false);
      setLoading(false);
    }   
  }, [graphData])
  


  if(loading){
    return (
      <div className={loading ? "Loader" : "Loader-Result"}>
        <BeatLoader
          color={"#D0021B"}
          loading={loading}
          size={25}
          margin={2}
        />
      </div>
    )
  }
  
  if (localStorage != null) {
    return (
      <DashboardLayout>
        {rolePageException && !hideConfirmationCard && !loading && (
          <Modal style={"pad-0"}>
            <Grid justifyContent={"center"} container>
              <Grid item xs={12} md={12} xl={12}>
                <Card
                  className="shake-animate"
                  sx={{
                    background:
                      "linear-gradient(90deg, rgb(62, 158, 225) 100%, rgb(71, 160, 224) 48%, rgb(38, 143, 217) 0%);",
                  }}
                >
                  <SuiBox p={3}>
                    <SuiBox>
                      <SuiTypography color="white" variant="h6" fontWeight="light">
                        Are you assigned to the correct manager and campaign:
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      display="flex"
                      mt={3}
                      mb={3}
                      gap={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <SuiBox>
                        <SuiTypography color="white" mb={0.5} variant="h6" fontWeight="bold">
                          Current Manager
                        </SuiTypography>
                        <SuiInput
                          title="Currently Assigned Manager"
                          value={
                            userAssignedManager.FullName || "Loading..."
                          }
                        />
                      </SuiBox>
                      <SuiBox>
                        <SuiTypography color="white" mb={0.5} variant="h6" fontWeight="bold">
                          Current Campaign
                        </SuiTypography>
                        <SuiInput
                          title="Currently Assigned Campaign"
                          value={
                            (userAssignedCampaigns &&
                              userAssignedCampaigns.length > 0 &&
                              userAssignedCampaigns[0].Code) ||
                            "No Campaign Set"
                          }
                        />
                      </SuiBox>
                    </SuiBox>
                    <Grid
                      mt={5}
                      flexDirection={"row"}
                      alignItems={"center"}
                      container
                      gap={2}
                      justifyContent={"center"}
                    >
                      <SuiButton disabled={submissionLoading} onClick={() => { handleConfirmation(); }} sx={{ padding: ".5rem 3rem" }} color="success" variant="gradient">
                        Yes
                      </SuiButton>
                      <SuiButton disabled={submissionLoading} onClick={() => { setDisplayHelpText(true);setHideConfirmationCard(true); }} sx={{ padding: ".5rem 3rem" }} color="error" variant="gradient">
                        No
                      </SuiButton>
                    </Grid>
                  </SuiBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} xl={7}></Grid>
            </Grid>
          </Modal>
        )}
        {rolePageException && showCancellationPopup && (
          <Modal>
            <SuiBox
              alignItems="center"
              textAlign="center"
              display={"flex"}
              flexDirection="column"
            >
              
              <SuiTypography fontWeight="bold" variant="h5">
                Escalation Cancel Confirmation
              </SuiTypography>
              <SuiTypography fontWeight="light" variant="h6">
                Are you sure you want to cancel your pending <b>{approvals[0]?.RequestType.toLowerCase() || ''}</b> ?
              </SuiTypography>
              <SuiBox mt={3} display={"flex"} gap={2} container>
                <SuiButton
                  onClick={() => {
                    cancelEscalation();
                  }}
                  sx={{ padding: ".5rem 3rem" }}
                  color="success"
                  variant="gradient"
                  disabled={submissionLoading}
                >
                  Yes
                </SuiButton>
                <SuiButton
                  onClick={() => {
                    setShowCancellationPopup(false);
                  }}
                  sx={{ padding: ".5rem 3rem" }}
                  color="error"
                  variant="gradient"
                >
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Modal>
        )}
        <Header />

        <SuiBox mt={4} mb={3}>
          <Grid justifyContent={"center"} gap={3} container>
            <Grid item xs={12} md={12} xl={4}>
              <ProfileInfoCard
                title="profile information"
                info={{
                  email: graphData?.email,
                  employeeNumber: employeeNumber,
                  mobilePhone: mobileNumber,
                  diallerUserName: diallerUserName,
                }}
                action={{ route: "", tooltip: "Edit Profile" }}
              />
            </Grid>
            <Grid
              style={ displayHelpText? { border: "#ff8d49 5px solid", borderRadius: "15px 15px 15px 15px"} : {}}
              position={"relative"}
              rowGap={3}
              display={"flex"}
              flexDirection={"column"}
              xs={12}
              md={12}
              xl={7}
            >
              {(displayHelpText || disableEscalations) && (
                <Grid
                  alignItems="end"
                  gap={14}
                  justifyContent="end"
                  display={"flex"}
                  item
                  xs={12}
                  md={12}
                  xl={12}
                >
                  {displayHelpText && (
                    <div
                      style={{
                        backgroundColor: "#ff8d49",
                        flex: 1,
                        width: "50%",
                        padding: "1rem",
                        borderRadius: "5px 0px 15px 0px",
                      }}
                      className="escalation-info"
                    >
                      <SuiTypography variant="h6" color="white">
                        Escalate if you are not assigned to the correct
                        manager or campaign below
                      </SuiTypography>
                    </div>
                  )}
                  {rolePageException && showCancelEscalationButton && (
                    <>
                      
                      <SuiButton
                        padding={1}
                        onClick={() => { setShowCancellationPopup(true);}}
                        variant="text"
                        color="error"
                      >
                        <Icon fontSize="default" color="inherit">
                          cancel
                        </Icon>
                        &nbsp;
                        Cancel Pending Escalation
                      </SuiButton>
                    </>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <ManagerCard
                  rolePageException={rolePageException}
                  manager={userAssignedManager}
                  disableEscalations={disableEscalations}
                  alert={alert}
                  getApprovals={getApprovals}
                  setDisplayHelpText={setDisplayHelpText}
                />
              </Grid>
              <Grid item xs={12}>
                <CampaignCard
                  rolePageException={rolePageException}
                  campaigns={userAssignedCampaigns}
                  disableEscalations={disableEscalations}
                  alert={alert}
                  getApprovals={getApprovals}
                  setDisplayHelpText={setDisplayHelpText}
                />
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default Overview;