import { useEffect, useState, useCallback  } from "react";
import React from 'react';
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import MovementReasonsSelect from "../components/MovementReasonsSelect";
import CampaignProvider from "../../../providers/CampaignProvider";
import UsersProvider from "../../../providers/UsersProvider";
import { useForm } from 'react-hook-form';
import ActionRequestButtons from "../components/ActionRequestButtons";
import requesterErrorMessages from '../../../static/messages/errorMessages.json';

export const UserCampaignUpdate = React.memo(({ setApiRequestObj }) => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue  } = useForm();
  const [errorMessages, setErrorMessages] = useState(null);
  const [managerOptions, setManagerOptions] = useState([]);
  const [managerSuboridinates, setManagerSuboridinates] = useState([]);
  const [managerCampaigns, setManagerCampaigns] = useState(null);
  const [subsetCampaigns, setSubsetCampaigns] = useState([]);
  const [selectedManager, setSelectedManager] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [movementReasonId, setMovementReasonId] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [loggedInUserRole, setLoggedInUserRole] = useState(localStorage.getItem("roleName"));

  const onSubmit = async() => {      
    let approvers = [selectedManager?.EmployeeNumber];

    if(selectedManager?.ManagerUsers[0]?.EmployeeNumber) {
      approvers.push(selectedManager?.ManagerUsers[0]?.EmployeeNumber);
    } 

    if(movementReasonId && selectedUsers && localStorage.getItem("EmployeeNumber")){
      console.log('FINAL RESULT:', {
        RequestType: "UPDATE CAMPAIGNS",
        RequestDescription: "portal-request",
        CreatedBy: localStorage.getItem("EmployeeNumber"),
        RequesterUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        UserHierarchyMovementReasonId: movementReasonId,
        ApproverUserEmployeeNumbers: approvers,
        EmployeeMovements: selectedUsers.map((user) => ({
          EmployeeNumber: user.value,
          CampaignCodes: user.CampaignCodes
        })),
        EffectiveDate: null
      })
      setApiRequestObj({
        RequestType: "UPDATE CAMPAIGNS",
        RequestDescription: "portal-request",
        CreatedBy: localStorage.getItem("UserName") || null,
        RequesterUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        UserHierarchyMovementReasonId: movementReasonId,
        ApproverUserEmployeeNumbers: approvers,
        EmployeeMovements: selectedUsers.map((user) => ({
          EmployeeNumber: user.value,
          CampaignCodes: user.CampaignCodes
        })),
        EffectiveDate: null
      })      
    } 
  };
  const handleFormSubmit = handleSubmit(onSubmit);

  const updateUserListCampaignAssignment = (employeeNumber, campaignCode) => {  
    let arr = selectedUsers;
    const employee = arr.find(emp => emp.value === employeeNumber);
    if (employee) {
      employee.CampaignCodes = [campaignCode];
    }
    setSelectedUsers(arr);
  };

  const getManagers = async(roleName) => {
    console.log('roleName:', roleName)
    setFetchingData(true);
    const users = await UsersProvider.getUsersByRole({ roleName: roleName });

    let options = users.map((user) => ({
      value: user.EmployeeNumber	,
      label: `${user.FullName} ( ${user.EmployeeNumber} )`
    }));

    setFetchingData(false);
    setManagerOptions(options);
  };

  const getCampaigns = async(employeeNUmber) => {
    setFetchingData(true);
    const campaigns = await CampaignProvider.getManagerUserCampaigns(employeeNUmber);

    return campaigns;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '40px', // Set your desired height here
      minHeight: '40px', // Adjust minimum height to match
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px', // Ensure the value container also matches
      padding: '0 8px', // Adjust padding if needed
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0', // Remove any default margin
      padding: '0', // Remove padding to align text
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '40px', // Ensure the indicators match the height
    }),
  };

  const getManagerInfo = async(employeeNumber) => {
    setManagerSuboridinates([]);

    setFetchingData(true);
    const managerData = await UsersProvider.getUsersSearch({ employeeNumber: employeeNumber });

    if(managerData && managerData.length === 1){
      setSelectedManager(managerData[0]);
      setManagerSuboridinates(managerData[0].SubordinateUsers);
      const campaigns = await getCampaigns(employeeNumber);
      setManagerCampaigns(campaigns);
      let subCampaigns = [];

      for (let index = 0; index < campaigns.length; index++) {
        const parentCampaign = campaigns[index];
        subCampaigns.push(...parentCampaign.SubsetCampaigns);
      }
      console.log('subCampaigns:', subCampaigns)
      if (subCampaigns && subCampaigns.length > 0) {
        setSubsetCampaigns(subCampaigns);
    }
    }
    setFetchingData(false);
  }

  useEffect(() => {
    if(["Admin", "General Manager"].includes(loggedInUserRole)){
      getManagers('Junior Manager');
    }else{
      getManagerInfo(localStorage.getItem("EmployeeNumber"));
    }
    setErrorMessages(requesterErrorMessages['teamManagement'].requests['userCampaignUpdate']);
  }, [])
  
  return (
    <>
    {errorMessages && <>
      {["Admin", "General Manager"].includes(loggedInUserRole) &&  <SuiBox mt={6} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">Manager Selection</SuiTypography>
        <SuiBox position="relative">
          <Select
            key={`selectedManager`}
            id={`selectedManager`}
            register={register('selectedManager', errorMessages['selectedManager'])}
            options={managerOptions}
            
            placeholder={`Select the junior manager`}
            onChange={(e) => {getManagerInfo(e.value); setValue('selectedManager', e.value);}}
            isSearchable={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['selectedManager'] && <p className="error msg pad">{errorMessages['selectedManager']['required']}</p>}
      </SuiBox>}
      <SuiBox mt={6} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">User Selection</SuiTypography>
        <SuiBox mt={1} position="relative">
          <Select
            key={`userOptions`}
            id={`userOptions`}
            register={register('selectedUsers', errorMessages['selectedUsers'])}
            isLoading={fetchingData}
            isDisabled={fetchingData}
            options={managerSuboridinates.map((user) => ({
              value: user.EmployeeNumber,
              label: `${user.FullName	} ( ${user.EmployeeNumber} )`
            }))}
            placeholder={`Select users you want to update`}
            onChange={(e) => {setSelectedUsers(e); setValue('selectedUsers', e);}}
            isSearchable={true}
            isMulti={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['selectedUsers'] && <p className="error msg pad">{errorMessages['selectedUsers']['required']}</p>}

      </SuiBox>


      {/* ALLOW THE USER TO SELECT A CAMPAIGN AND ASSIGN TO ALL THE USRES THEY HAVE SELECT       */}
      <SuiBox mt={6} mb={2} lineHeight={1}>
          {selectedUsers && selectedUsers.length > 0 && <SuiTypography variant="h6">Campaign Assignment Selection</SuiTypography>}
          {selectedUsers && selectedUsers.length > 0 && selectedUsers.map((user) => {
            return (
              <>
              <SuiBox px={3}>
                <SuiBox key={user.value} display="grid" gridTemplateColumns=".6fr 1fr" mt={2} alignItems="center"> 
                  <SuiTypography sx={{fontSize: ".85rem"}} color="dark">{user.label}</SuiTypography>
                  <Select
                    key={`campaignAssignment`}
                    id={`campaignAssignment`}
                    register={register('campaignAssignment', errorMessages['campaignAssignment'])}
                    isLoading={fetchingData}
                    isDisabled={fetchingData}
                    // styles={customStyles} 
                    options={subsetCampaigns.map((campaign) => ({
                      value: campaign.Code,
                      label: `${campaign.Code}`
                    })) || []}
                    placeholder={`Select campaigns`}
                    onChange={(e) => {updateUserListCampaignAssignment(user.value, e.value); setValue('campaignAssignment', e.value);}}
                    isSearchable={true}
                    classNamePrefix="custom-select"
                    className="select-control select-one select-with-html"
                  />
                </SuiBox> 
              </SuiBox>
              </>
            )
          })}
        {errors['campaignAssignment'] && <p className="error msg pad">{errorMessages['campaignAssignment']['required']}</p>}

      </SuiBox>

      <MovementReasonsSelect
        requestType={'movement'} 
        setReasonId={setMovementReasonId} 
        register={register}
        errorMessages={errorMessages}
        errors={errors} 
        setValue={setValue}
       />
      <ActionRequestButtons onSubmit={handleFormSubmit}/>
    </>}
    </>
  )
});
