import { useEffect, useState } from "react";
import React from 'react';
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import SuiInput from "components/SuiInput";
import MovementReasonsSelect from "../components/MovementReasonsSelect";
import CampaignProvider from "../../../providers/CampaignProvider";
import UsersProvider from "../../../providers/UsersProvider";
import { useForm } from 'react-hook-form';
import ActionRequestButtons from "../components/ActionRequestButtons";
import requesterErrorMessages from '../../../static/messages/errorMessages.json';
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import useAlert from "../../../common/useAlert";


import ErrorMessage from "../components/ErrorMessage";


export const CampaignEscalation = React.memo(({ preloadData, setApiRequestObj }) => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue  } = useForm();
  const { alert } = useAlert();

  const [managerCampaigns, setManagerCampaigns] = useState([]);
  const [errorMessages, setErrorMessages] = useState(null);
  const [selectedParentCampaignObj, setSelectedParentCampaignObj] = useState(null);
  const [selectedSubsetCampaignCode, setSelectedSubsetCampaignCode] = useState(null);
  const [movementReasonId, setMovementReasonId] = useState(null);
  const requestor = preloadData?.Requestor || null;
  const actionedForManager = preloadData?.RequestData?.ManagerUserEmployeeNumber || null;

  if(preloadData && preloadData.RequestStatus !== "NEW"){
    alert({
      icon:'error',
      title: 'This request has already been actioned',
    });
   
    setTimeout(() =>  window.location.reload(), 2000);

    return;
  }

  const getManagerCampaigns = async(manager) => {
    const campaigns = await CampaignProvider.getManagerUserCampaigns(manager.EmployeeNumber);
    setManagerCampaigns(campaigns);
  }

  const setDiallerCampaigns = () => {
    if(!selectedParentCampaignObj) return [];
    const campaign = managerCampaigns.find(campaign => campaign.Code === selectedParentCampaignObj.value);
  
    if (campaign && campaign.SubsetCampaigns) {
      const subsetCampaigns = campaign.SubsetCampaigns;
  
      return subsetCampaigns.map((campaign) => ({
        value: campaign.Code,
        label: `${campaign.Name} ( ${campaign.Code} )`
      }));
    }
  
    return [];
  };


  const onSubmit = () => {

    if(movementReasonId && selectedSubsetCampaignCode?.value && preloadData?.RequestId && localStorage.getItem("EmployeeNumber")){
      setApiRequestObj({
        RequestType: 'APPROVE ESCALATION',
        RequestDescription: "portal-request",
        CreatedBy: localStorage.getItem("UserName") || null,
        RequesterUserEmployeeNumber: actionedForManager,
        ApproverUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        UserHierarchyMovementReasonId: movementReasonId,
        CampaignCode: selectedSubsetCampaignCode.value,
        EscalationRequestId: preloadData.RequestId
      })
    }
    
  };
  const handleFormSubmit = handleSubmit(onSubmit);

  useEffect(async() => {
    const manager = await UsersProvider.getUserByEmployeeNumber(preloadData?.RequestData?.ManagerUserEmployeeNumber);
    await getManagerCampaigns(manager);
    setErrorMessages(requesterErrorMessages['teamManagement'].requests['campaignEscalation']);
  }, [])

  if(!preloadData){
    return (
      <>
      <SuiBox display={'flex'} gap={2} alignItems='center' >
        <SuiTypography
            component="span"
            variant="body2"
            color={"error"}
          >
            Escalations must be actioned from the Approvals tab:
          </SuiTypography>
        <a href="/approvals">
          <SuiTypography
            component="span"
            variant="body2"
            color={"dark"}
            mb
          >
            Approvals Page
            &nbsp;
            <Icon>link</Icon>
          </SuiTypography>
        </a>
        </SuiBox>
      </>
    )
  }
  
  return (
    <>
    {errorMessages && <>
      <SuiBox mt={4} mb={2} lineHeight={1}>
        <SuiTypography sx={{paddingBottom: '0.5rem'}} variant="h6">Request being actioned for</SuiTypography>
        <SuiInput
          label={`User`}
          id={`userEscalationRef`}
          name={`userEscalationRef`}
          disabled={true}
          value={`${requestor?.FullName} ( ${requestor?.EmployeeNumber} )`}
        />
        {/* <p className="error msg pad">{`something`}</p> */}
      </SuiBox>
      <SuiBox mt={4} mb={2} lineHeight={1}>
        <SuiTypography variant="h6">Campaign Assignment</SuiTypography>
        <SuiBox position="relative">
          <Select
            key={`parentCampaignCode`}
            id={`parentCampaignCode`}
            register={register('parentCampaignCode', errorMessages['parentCampaignCode'])}
            options={managerCampaigns.map((campaign) => ({
                  value: campaign.Code,
                  label: `${campaign.Name} ( ${campaign.Code} )`
            }))}
            // value={selectedParentCampaignObj}
            placeholder={`Search campaigns by Campaign Code`}
            onChange={(e) => {setSelectedParentCampaignObj(e); setSelectedSubsetCampaignCode(null); setValue('subsetCampaignCode', undefined); setValue('parentCampaignCode', e);}}
            isSearchable={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['parentCampaignCode'] && <p className="error msg pad">{errorMessages['parentCampaignCode']['required']}</p>}
        <SuiBox mt={1} position="relative">
          <Select
            key={`subsetCampaignCode`}
            id={`subsetCampaignCode`}
            register={register('subsetCampaignCode', errorMessages['subsetCampaignCode'])}
            options={setDiallerCampaigns()}
            value={selectedSubsetCampaignCode || ''}
            placeholder={`Select a Subset/Dialer Campaign`}
            onChange={(e) => {setSelectedSubsetCampaignCode(e); setValue('subsetCampaignCode', e.value);}}
            isSearchable={true}
            classNamePrefix="custom-select"
            className="select-control select-one select-with-html"
          />
        </SuiBox>
        {errors['subsetCampaignCode'] && <p className="error msg pad">{errorMessages['subsetCampaignCode']['required']}</p>}

      </SuiBox>

      <MovementReasonsSelect
        requestType={'movement'} 
        setReasonId={setMovementReasonId} 
        register={register}
        errorMessages={errorMessages}
        errors={errors} 
        setValue={setValue}
       />
      <ActionRequestButtons onSubmit={handleFormSubmit}/>
    </>}
    </>
  )
});
